import { PayMethod } from 'models/pay_method';

export const onSavePayMethod = (source) => {
  const card = source.card;
  const postalCode = source.owner && source.owner.address && source.owner.address.postal_code;
  const payMethod = new PayMethod({
    type: 'pay_method',
    stripe_id: source.id,
    kind: card.brand,
    method: card.tokenization_method,
    exp_month: card.exp_month,
    exp_year: card.exp_year,
    last_digits: card.last4,
    postal_code: postalCode,
  });
  return payMethod.save();
};
