import { apiClient } from 'mobx-rest';

import {
  AcceptsDonation
} from 'models/accepts_donation';

export class AddDonationFormModel extends AcceptsDonation {
  urlRoot() {
    return 'user/memberships';
  }

  addDonation(data) {
    const { promise } = apiClient().post(
      `${this.urlRoot()}/${this.get('membershipID')}/add_donation`,
      data
    );
    return promise;
  }

  get currency() {
    return this.get('currency');
  }
}