import '../styles/geosuggest.scss';

import Geosuggest from 'react-geosuggest';
import PropTypes from 'prop-types';
import React from 'react';

import { Address } from 'shared/organisms/address';

export class AddressSelector extends React.Component {
  static propTypes = {
    value: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.object,
    invalid: PropTypes.bool,
    disabledPlaceholder: PropTypes.string
  };

  static defaultProps = {
    placeholder: '123 Main St., City, State ZIP',
    disabledPlaceholder: 'Contact support to add an address',
    disabled: false,
    invalid: false
  };

  state = { viewState: 'static' };

  enableEdit = () =>
    this.setState({ viewState: 'editing' }, this._focusGeosuggest);

  disableEdit = () => this.setState({ viewState: 'static' });

  _focusGeosuggest = () => this._geoSuggest.focus();

  _onBlur = address => {
    this.props.onChange(address);
    this.disableEdit();
  };

  _onSelect = googlePlacesObject => {
    const value =
      googlePlacesObject == undefined
        ? ''
        : googlePlacesObject.gmaps.formatted_address;

    this.props.onChange(value);
    this.disableEdit();
  };

  renderSuggestItem = suggestion => <Address address={suggestion.label} />;

  render() {
    const {
      value,
      placeholder,
      className,
      disabled,
      invalid,
      disabledPlaceholder
    } = this.props;

    return this.state.viewState == 'editing' ? (
      <Geosuggest
        ref={i => {
          this._geoSuggest = i;
        }}
        className={className}
        placeholder={placeholder}
        initialValue={value || ''}
        renderSuggestItem={this.renderSuggestItem}
        onSuggestSelect={this._onSelect}
        onBlur={this._onBlur}
      />
    ) : (
      <AddressStatic
        address={value}
        onClick={this.enableEdit}
        disabled={disabled}
        invalid={invalid}
        placeholder={placeholder}
        disabledPlaceholder={disabledPlaceholder}
      />
    );
  }
}

class AddressStatic extends React.Component {
  static propTypes = {
    address: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    invalid: PropTypes.bool,
    disabledPlaceholder: PropTypes.string,
    renderHiddenInput: PropTypes.bool,
    hiddenInputName: PropTypes.string
  };

  static defaultProps = { renderHiddenInput: true, hiddenInputName: 'address' };

  render() {
    const {
      placeholder,
      disabled,
      disabledPlaceholder,
      renderHiddenInput,
      hiddenInputName
    } = this.props;

    const address = this.props.address
      ? this.props.address
      : disabled
      ? disabledPlaceholder
      : placeholder;

    return (
      <div
        tabIndex={0}
        autoFocus={true}
        onFocus={() => {
          !this.props.disabled && this.props.onClick();
        }}
        className={`h-auto form-control ${
          this.props.invalid ? 'is-invalid' : ''
        } ${this.props.address ? '' : 'placeholder'}`}
      >
        <Address address={address} />
        {renderHiddenInput && (
          <input
            name={hiddenInputName}
            value={this.props.address}
            type="hidden"
          />
        )}
      </div>
    );
  }
}
