import React from 'react';
import { apiClient } from 'mobx-rest';
import { observer } from 'mobx-react';

import cart from 'models/cart';
import { CartForm } from './form';
import { JoinPTADefaultPropTypes } from 'applets/join_pta/JoinPTADefaultPropTypes';
import { Teachers } from './types';

const nextPath = '/checkout';
const prevPath = '/register';

@observer
export class CartView extends React.Component<JoinPTADefaultPropTypes, { teachers: Teachers }> {
  state = { teachers: [] };

  constructor(props) {
    super(props);
    if (!cart.validForCartStep) {
      this.props.history.push(prevPath);
    }
  }

  componentDidMount() {
    this.fetchTeachers();
  }

  async fetchTeachers() {
    try {
      const { promise } = apiClient().get(`user/org_units/${this.props.applet.get('org_unit').id}`, {
        fields: { org_unit: 'teachers' },
      });
      const { teachers } = await promise;
      this.setState({ teachers });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const { teachers } = this.state;

    if (!cart.validForCartStep) return null;

    return <CartForm {...this.props} nextPath={nextPath} prevPath={prevPath} teachers={teachers} />;
  }
}
