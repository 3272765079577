import React from 'react';
import { Col, Row } from 'reactstrap';

import { Logo } from 'shared/images';

type OrgHeaderProps = {
  logoUrl?: string;
  logoHeight?: number;
  logoWidth?: number;
  orgName?: string;
  orgDetail?: string;
  role?: JSX.Element | string;
  roleDetail?: string;
  rolePrintable?: boolean;
};

export const OrgHeader: React.FC<OrgHeaderProps> = ({
  logoHeight = 64,
  logoWidth = 64,
  orgName = 'Organization',
  logoUrl,
  orgDetail,
  role,
  roleDetail,
  rolePrintable = true
}) => {
  return (
    <div className="organization-header">
      <Row className="align-items-center">
        <Col xs={12} md={role ? 8 : 12} className="d-flex align-items-center notranslate">
          <div className="mr-3 my-1" style={{ width: logoWidth, height: logoHeight }}>
            <Logo
              url={logoUrl}
              height={logoHeight}
              width={logoWidth}
              style={{ width: logoWidth, height: logoHeight }}
            />
          </div>
          <div style={{ lineHeight: 1.0 }}>
            <h1 className="h4 mb-0 pb-0">{orgName}</h1>
            <span className="small">{orgDetail}</span>
          </div>
        </Col>
        {role && (
          <Col xs={12} md={4} className={`text-center${!rolePrintable && " d-print-none"}`}>
            <div className="nobr">{role}</div>
            <span className="small nobr">{roleDetail}</span>
          </Col>
        )}
      </Row>
    </div>
  );
};
