import React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'reactstrap';

import Donations from 'shared/donations';
import { AddDonationFormModel } from 'models/forms/add_donation';
import { DonationLevel, DonationLevels } from 'models/leaders/donation_level';

type DonationLevelPreviewProps = {
  defaultDonation?: number;
  donationMessage: string;
  levels: DonationLevels;
};

@observer
export default class DonationLevelPreview extends React.Component<DonationLevelPreviewProps, {}> {
  model: AddDonationFormModel;

  constructor(props) {
    super(props);
    this.model = new AddDonationFormModel({ currency: 'USD' });
  }

  render() {
    const { defaultDonation, donationMessage, levels } = this.props;
    const options = levels.models.map((m: DonationLevel) => m.toJS());

    return (
      <div className="px-5">
        <strong className={'mb-3'}>DONATION PREVIEW</strong>
        <Table>
          <tbody>
            <Donations.DonationRow
              buttonText="Donate"
              defaultDonation={defaultDonation}
              message={donationMessage}
              model={this.model}
              disabled
            />
          </tbody>
        </Table>
        <div className="mt-2">
          <Donations.Input
            model={this.model}
            options={options}
            defaultDonation={defaultDonation}
            afterSelect={() => {}}
            disabled
            menuIsOpen
          />
        </div>
      </div>
    );
  }
}
