import { computed } from 'mobx';
import { Setting, Settings } from 'models/setting';

const defaultTeacherLookupSettingConfig = { excluded: [], included: [], disable_user_created: false };

export const parseTeachers = (str: string): string[] => {
  return str.match(/[a-zA-Z-'\/]+/g) || []
}

export const isValid = (str: string): boolean => {
  const matches = str.match(/[^a-zA-Z-'\/ ,\n]+/);
  
  return !matches || matches.length == 0;
}

export class TeacherLookupSetting extends Setting {
  get excluded() {
    return this.safeConfiguration['excluded'];
  }

  get included() {
    return this.safeConfiguration['included'];
  }

  @computed
  get safeConfiguration() {
    // implement Settings.safeConfiguration
    const config = this.configuration;
    if (config) {
      Object.keys(defaultTeacherLookupSettingConfig).forEach((k) => {
        if (!config[k]) config[k] = defaultTeacherLookupSettingConfig[k];
      });
      return config;
    } else {
      return defaultTeacherLookupSettingConfig;
    }
  }
}

export class TeacherLookupSettings extends Settings {
  model() {
    return TeacherLookupSetting;
  }

  fetch() {
    return super.fetch({
      data: {
        filter: {
          type: 'Settings::TeacherLookup',
        },
      },
    });
  }
}
