import React from 'react';
import { Alert } from 'reactstrap';
import { observer } from 'mobx-react';

import addCartToBugsnag from 'applets/join_pta/add_cart_to_bugsnag';
import cart from 'models/cart';
import currentUser from 'models/current_user';
import Footer from 'applets/join_pta/footer';
import { JoinPTADefaultPropTypes } from 'applets/join_pta/JoinPTADefaultPropTypes';
import { NextButton } from 'shared/form_buttons';
import { StandardField, StandardForm } from 'shared/standard_field';
import Select from 'react-select';

const nextPath = '/cart';

@observer
export class RegistrationForm extends React.Component<
  JoinPTADefaultPropTypes,
  { showMemTypeErrorStyles?: boolean; socialLogin: boolean }
> {
  membershipTypeRef: any = React.createRef<Select>();

  constructor(props) {
    super(props);

    if (currentUser.get('identities').toJS().length) this.state = { socialLogin: true };
    else this.state = { socialLogin: false, showMemTypeErrorStyles: false };
  }

  nextStep = () => {
    cart.items.models[0].users.set(
      [
        {
          id: currentUser.get('id'),
          name: currentUser.get('name'),
          membership_type: cart.get('membership_type'),
        },
      ],
      { remove: false }
    );
    this.props.history.push(nextPath);
  };

  submit = () => {
    if (!(this.membershipTypeRef.current?.state.value || this.membershipTypeRef.current?.state.inputValue)) {
      this.setState({ showMemTypeErrorStyles: true });
    } else
      return new Promise((resolve, reject) => {
        if (this.state.socialLogin) {
          const request = currentUser.save({
            type: 'user',
            name: currentUser.get('name'),
            email: currentUser.get('email'),
          });
          request.promise
            .then(() => {
              this.nextStep();
              // this is a bit weird, but basically we don't want to StandardForm to change state because we're navigating anyway
              reject();
            })
            .catch((e) => reject(e));
        } else {
          this.nextStep();
          reject();
        }
      });
  };

  render() {
    addCartToBugsnag(cart);

    const flash = window['FLASH'].alert || window['FLASH'].notice;
    const membershipTypeOptions = this.props.applet.membershipTypes.asRichOptions.map((mT) => {
      mT.label = mT.name;
      return mT;
    });

    const defaultMembershipType = cart.has('membership_type')
      ? membershipTypeOptions.find((mType) => cart.get('membership_type').get('id') == mType.value.get('id'))
      : null;

    return (
      <StandardForm
        id="registration-form"
        className="d-flex flex-column flex-grow-1"
        onSubmit={this.submit}
        vanillaForm={false}
      >
        {this.state.socialLogin ? (
          <>
            <StandardField
              type="name"
              label="Your name"
              placeholder="Your full name"
              defaultErrorMessage="Full name required"
              name="name"
              model={currentUser}
              required
            />
            <StandardField
              type="email"
              label="Your email"
              placeholder="Your email"
              defaultErrorMessage="Valid email required"
              name="email"
              model={currentUser}
              required
            />
          </>
        ) : flash ? (
          <Alert color="success">{flash}</Alert>
        ) : (
          <p className="text-center strong">Welcome, {currentUser.get('name')}!</p>
        )}
        <StandardField
          type="react-select"
          options={membershipTypeOptions}
          reactSelectInnerRef={this.membershipTypeRef}
          id="membership-type-select"
          placeholder="Please select"
          label="Membership type"
          name="membership_type"
          model={cart}
          autoFocus={!cart.has('membership_type')}
          reactSelectDefaultValue={defaultMembershipType}
          showErrorStyles={this.state.showMemTypeErrorStyles}
          resetErrorStyles={() => this.setState({ showMemTypeErrorStyles: false })}
          required
        />
        <Footer
          className="remove-card-body-margin"
          left={
            <div className="d-flex align-items-center h-100">
              <span className="brand nobr">Powered by TOTEM</span>
            </div>
          }
          right={<NextButton />}
        />
      </StandardForm>
    );
  }
}
