import map from 'lodash/map';
import { apiClient } from 'mobx-rest';

import csrfToken from 'shared/csrf_token';
import mobxJsonApiAdapter from 'mobx-rest-jsonapi-adapter';

import jquery from 'jquery';

const $ = jquery;

const apiPath = '/api/v1/';

export const jsonApiMobxTranslator = {
  convertJSONApiResponseToMobx(jsonApiRequestDataSerialized) {
    if (jsonApiRequestDataSerialized == '') {
      return '';
    }
    const jsonApiRequestData = JSON.parse(jsonApiRequestDataSerialized);
    const data = jsonApiRequestData.data;

    if (Array.isArray(data)) {
      const mobxCollectionData = map(data, object => {
        return includeRelatedResources(object, jsonApiRequestData);
      });
      return JSON.stringify({
        models: mobxCollectionData,
        raw: jsonApiRequestData
      });
    } else {
      const model = includeRelatedResources(data, jsonApiRequestData);
      return JSON.stringify(model);
    }
  },

  convertJSONApiErrorsToMobx(errors) {
    const errObject = {};
    errors.forEach(e => {
      const source = e['source']; // possibilities 1) primary object err -> '/data' 2) specific attribute err -> '/data/attributes/title' or '/data/relationships/comments'
      if (source && source['pointer']) {
        // MODEL ERRORS
        const sourceArr = source['pointer'].split('/');
        if (sourceArr[sourceArr.length - 1] == 'data') {
          if (sourceArr[sourceArr.length - 2] == 'email') {
            errObject[sourceArr[sourceArr.length - 2]] = [e.code.message];
          } else {
            errObject[sourceArr[sourceArr.length - 2]] = [e.detail];
          }
        } else {
          let name = sourceArr[sourceArr.length - 1];
          if (sourceArr[sourceArr.length - 2] == 'attributes') {
            // case for specific attribute error
            errObject[name]
              ? errObject[name].push(e['detail'])
              : (errObject[name] = [e['detail']]);
          } else if (sourceArr[sourceArr.length - 2] == 'relationships') {
            // case for relationship error
            name = `relationship:${name}`;
            errObject[name]
              ? errObject[name].push(e['detail'])
              : (errObject[name] = [e['detail']]);
          }
        }
      } else if (source && source['parameter']) {
        // CONTROLLER PARAM ERRORS
        const name = source['parameter'];
        errObject[name]
          ? errObject[name].push(e['detail'])
          : (errObject[name] = [e['detail']]);
        if (e['code'] == 'not_found') errObject['not_found'] = true;
      }
    });
    return errObject;
  }
};

function includeRelatedResources(object, raw) {
  if (raw && raw.included && object && object.relationships) {
    for (const [type, relationshipObj] of Object.entries(
      object.relationships
    )) {
      if (Array.isArray(relationshipObj.data)) {
        // has many relationship
        mapPluralRelationships(object, type, relationshipObj.data, raw);
      } else {
        // has one relationship
        mapSingleRelationship(object, type, relationshipObj.data, raw);
      }
    }
  }
  const model = Object.assign(
    {},
    { id: object.id, type: object.type },
    object.attributes
  );
  return model;
}

function mapPluralRelationships(object, relationshipType, relationships, raw) {
  let matches = map(relationships, rel => {
    return raw.included.find(el => {
      return el.id == rel.id;
    });
  });
  matches.forEach(match => {
    // nested relationships
    if (match.relationships) {
      for (const [type, relationship] of Object.entries(match.relationships)) {
        if (Array.isArray(relationship.data)) {
          mapPluralRelationships(match, type, relationship.data, raw);
        } else {
          mapSingleRelationship(match, type, relationship.data, raw);
        }
      }
    }
  });
  if (matches) {
    object.attributes[relationshipType] = map(matches, match => {
      return Object.assign(
        {},
        { id: match.id, type: match.type },
        match.attributes
      );
    });
  }
}

function mapSingleRelationship(object, relationshipType, relationshipObj, raw) {
  let match = raw.included.find(el => {
    return el.id == relationshipObj.id;
  });
  if (match) {
    // nested relationships
    if (match.relationships) {
      for (const [type, relationship] of Object.entries(match.relationships)) {
        if (Array.isArray(relationship.data)) {
          mapPluralRelationships(match, type, relationship.data, raw);
        } else {
          mapSingleRelationship(match, type, relationship.data, raw);
        }
      }
    }
    object.attributes[relationshipType] = Object.assign(
      {},
      { id: match.id, type: match.type },
      match.attributes
    );
  }
}

const commonOptions = {
  headers: {
    Accept: 'application/vnd.api+json',
    'Content-Type': 'application/vnd.api+json',
    'X-CSRF-Token': csrfToken()
  },
  dataFilter: jsonApiMobxTranslator.convertJSONApiResponseToMobx
};

const ajaxPrefilterOptions = {
  urlMatch: apiPath, // Filter urls you what to tamper with.
  errorWrapper: function(
    options,
    localCommonOptions,
    jqXHR,
    originalErrorHandler
  ) {
    if (!jqXHR.responseJSON) return;
    const errObject = jqXHR.responseJSON.errors
      ? jsonApiMobxTranslator.convertJSONApiErrorsToMobx(
          jqXHR.responseJSON.errors
        )
      : jqXHR.responseJSON.error;
    jqXHR.responseText = JSON.stringify({
      errors: errObject
    });
    if (originalErrorHandler) {
      originalErrorHandler(options, localCommonOptions, jqXHR);
    }
  }
  // successWrapper: function(options, localCommonOptions, jqXHR, originalSuccessHandler) {
  //   const data = jsonApiMobxTranslator.convertJSONApiResponseToMobx(jqXHR);
  //   jqXHR.responseText = JSON.stringify(data);
  //   jqXHR.responseJSON = data;

  //   originalSuccessHandler(options, localCommonOptions, jqXHR);
  // },
};

const alterAjaxResponses = function(ajaxPrefilterOptions) {
  const prefilterCallback = function(
    requestOptions,
    localCommonOptions,
    jqXHR
  ) {
    if (requestOptions.url.match(ajaxPrefilterOptions.urlMatch)) {
      const originalErrorHandler =
        localCommonOptions.error || requestOptions.error;
      localCommonOptions.error = requestOptions.error = function() {
        // Call wrapper that will modify the response object.
        ajaxPrefilterOptions.errorWrapper.call(
          null,
          requestOptions,
          localCommonOptions,
          jqXHR,
          originalErrorHandler
        );
      };
      // const originalSuccessHandler =
      //   localCommonOptions.success || requestOptions.success || jqXHR.done;
      // localCommonOptions.success = requestOptions.success = function() {
      //   ajaxPrefilterOptions.successWrapper.call(null, requestOptions, localCommonOptions, jqXHR, originalSuccessHandler);
      // };
    }
  };

  if (ajaxPrefilterOptions.dataTypes) {
    $.ajaxPrefilter(ajaxPrefilterOptions.dataTypes, prefilterCallback);
  } else {
    $.ajaxPrefilter(prefilterCallback);
  }
};

alterAjaxResponses(ajaxPrefilterOptions);

export const initializeTotemClient = function(apiPath) {
  return apiClient(mobxJsonApiAdapter, { apiPath, commonOptions });
};

const totemClient = apiClient(mobxJsonApiAdapter, { apiPath, commonOptions });

export default totemClient;
