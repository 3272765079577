import React, { FC } from 'react';
import { CardFooter } from 'reactstrap';

type FooterProps = {
  className?: string;
  left?: React.ReactNode;
  middle?: React.ReactNode;
  right?: React.ReactNode;
};
const Footer: FC<FooterProps> = ({ className, left, middle, right }: FooterProps) => (
  <CardFooter className={`d-flex flex-shrink-0 mt-md-0 nav-buttons ${className || ''}`}>
    <div style={{ flex: 1 }}>{left}</div>
    <div className="d-flex align-items-center justify-content-center" style={{ flex: 2 }}>
      {middle}
    </div>
    <div style={{ flex: 1 }}>{right}</div>
  </CardFooter>
);
export default Footer;
