import PropTypes from 'prop-types';
import React from 'react';

import { Col, Row } from 'reactstrap';

const RowCol = props => {
  return (
    <Row className={props.rowClass}>
      <Col className={props.colClass}>{props.children}</Col>
    </Row>
  );
};
RowCol.propTypes = {
  rowClass: PropTypes.string,
  colClass: PropTypes.string,
  children: PropTypes.node
};

export default RowCol;
