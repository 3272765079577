import PropTypes from 'prop-types';
import React from 'react';
import { PropTypes as MobxPropTypes } from 'mobx-react';

import Donations from 'shared/donations';
import { StandardField } from 'shared/standard_field';

export default class DonationInput extends React.Component {
  static propTypes = {
    model: MobxPropTypes.observableObject,
    options: MobxPropTypes.observableArray,
    afterSelect: PropTypes.func,
    defaultDonation: PropTypes.string,
    disabled: PropTypes.bool,
    menuIsOpen: PropTypes.bool,
    onSelectFocus: PropTypes.func,
    id: PropTypes.string
  };

  static defaultProps = {
    id: 'donation-input'
  };

  customAmtOption = {
    value: 'custom',
    label: 'Custom donation amount'
  };

  state = { selectVal: null };

  generateOptions = () => {
    return [
      ...this.props.options
        .map(dL => ({
          value: dL.amount,
          label: dL.headline,
          description: dL.description,
          amount: dL.formatted_amount
        })),
        // .sort((a, b) => {
        //   if (a.value > b.value) return -1;
        //   else if (a.value < b.value) return 1;
        //   return 0;
        // }),
      this.customAmtOption
    ];
  };

  initialSelectVal = options => {
    const selectOptVals = options.map(o => o.value);
    const donation = this.props.model.get('donation');
    const selectVal = donation
      ? selectOptVals.includes(donation)
        ? donation
        : 'custom'
      : null;
    return selectVal;
  };

  checkCustomAmountValid = e => {
    if (e.target.checkValidity() || e.target.value.length == 0) {
      this.props.model.set({
        donationMeta: {
          headline: 'Custom',
          description: this.customAmtOption.label
        }
      });
      this.props.afterSelect();
    }
  };

  onSelect = newOption => {
    const val = newOption ? newOption.value : null;
    const { afterSelect, model } = this.props;
    let callback =
      val == 'custom'
        ? () => {
            model.set({
              donationMeta: {
                headline: 'Custom',
                description: this.customAmtOption.label
              }
            });
            this.customInputRef.input.focus(); // b/c once display custom amount, want to focus input
          }
        : () => {
            const donationMeta = newOption
              ? {
                  headline: newOption.label,
                  description: newOption.description
                }
              : {};
            model.set({
              donation: val,
              donationMeta: donationMeta
            });
            afterSelect();
          };

    this.setState({ selectVal: val }, callback);
  };

  renderCustomAmountInput = () => {
    const { disabled, id, model } = this.props;
    return (
      <>
        <span className="float-right" style={{ maxWidth: '9rem' }}>
          <StandardField
            id={id}
            innerRef={ref => (this.customInputRef = ref)}
            type="currency"
            noLabel
            noFormGroup
            name="donation"
            model={model}
            onBlur={this.checkCustomAmountValid}
            onFocus={() => {
              if (!model.get('donation'))
                model.set({ donation: this.props.defaultDonation });
            }}
            autoFocus={this.props.options.length == 0} // i.e. select won't be displayed
            noRow
            required
            disabled={disabled}
          />
        </span>
        <div>Donation:</div>
        {Donations.renderSmileys(this.props.model.cleanDonation)}
      </>
    );
  };

  render() {
    const {
      disabled,
      options,
      id,
      model,
      menuIsOpen,
      onSelectFocus
    } = this.props;
    if (options.length == 0) return this.renderCustomAmountInput();
    else {
      const options = this.generateOptions();
      const selectVal = this.state.selectVal || this.initialSelectVal(options);
      const donation = model.get('donation');
      const selected = donation
        ? options.find(op => op.value == donation) || this.customAmtOption
        : null;

      return (
        <>
          <Donations.Select
            onChange={this.onSelect}
            onFocus={onSelectFocus}
            options={[
              {
                label: 'Donation',
                options: options
              }
            ]}
            defaultValue={selected}
            isDisabled={disabled}
            menuIsOpen={menuIsOpen}
            id={id}
          />
          {selectVal == 'custom' && (
            <div className="mt-3">{this.renderCustomAmountInput()}</div>
          )}
        </>
      );
    }
  }
}
