import appState from 'models/app_state';

import { TotemCollection, TotemModel } from 'models/totem';

export class DonationLevel extends TotemModel {
  urlRoot() {
    return `leaders/${appState.getOrgID}/donation_levels`;
  }

  get amount() {
    return this.get('amount');
  }

  get formattedAmount() {
    return this.get('formatted_amount');
  }

  get headline() {
    return this.get('headline');
  }
  get description() {
    return this.get('description');
  }
}

export class DonationLevels extends TotemCollection<DonationLevel> {
  urlRoot() {
    return `leaders/${appState.getOrgID}/donation_levels`;
  }

  model() {
    return DonationLevel;
  }
}

export default new DonationLevels();
