import React from 'react';

import moment, { Moment } from 'moment';
import Select from 'react-select';

interface PropsType {
  nextRenewDate: Moment;
  onlyPreregister: boolean;
  onSelect(selected: string): void;
}

interface StateType {
  editing: boolean;
  selected: any;
}

export class YearSelect extends React.Component<PropsType, StateType> {
  readonly state: StateType = {
    editing: true,
    selected: null,
  };

  getOptions() {
    const { nextRenewDate: renew, onlyPreregister } = this.props;
    const y = moment().year() - 1;

    return [
      ...(onlyPreregister
        ? []
        : [
            {
              value: 'now',
              label: (
                <>
                  <div>
                    <b>
                      {y}-{y + 1}
                    </b>{' '}
                    School Year
                  </div>
                  <div className="small">
                    Ends on {moment(renew).add(-1, 'd').format('MMM Do, YYYY')} ({renew.fromNow()})
                  </div>
                </>
              ),
            },
          ]),
      {
        value: 'preregister',
        label: (
          <>
            <div id="preregister-year-select" className="small text-muted">
              <b>PRE-REGISTER</b>
            </div>
            <div>
              <b>
                {y + 1}-{y + 2}
              </b>{' '}
              School Year
            </div>
            <div className="small">
              Membership effective {renew.format('MMM Do, YYYY')} to{' '}
              {moment(renew).add(1, 'y').add(-1, 'd').format('MMM Do, YYYY')}
            </div>
          </>
        ),
      },
    ];
  }

  render() {
    const { onlyPreregister, onSelect } = this.props;

    const options = this.getOptions();

    let defaultValue;
    if (onlyPreregister) defaultValue = options.find((o) => o.value == 'preregister');

    return this.state.editing ? (
      <div>
        <Select
          defaultValue={defaultValue}
          isDisabled={onlyPreregister}
          isSearchable={false}
          menuIsOpen={!onlyPreregister}
          onChange={(selected) => {
            this.setState({ editing: false, selected: selected });
            if (onSelect) onSelect(selected.value);
          }}
          options={options}
          placeholder="Select membership year"
        />
      </div>
    ) : (
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => {
          this.setState({ editing: true });
        }}
      >
        {this.state.selected.label}
      </div>
    );
  }
}
