import moment from 'moment';
import { computed } from 'mobx';

import parseMoney from 'models/concerns/parse_money';
import { MembershipTypes } from 'models/lookup';
import { TotemModel } from 'models/totem';

export class JoinPTA extends TotemModel {
  urlRoot() {
    return 'applets';
  }

  get orgUnit() {
    return this.get('org_unit');
  }

  @computed
  get defaultDonation() {
    return parseMoney(this.get('default_donation'));
  }

  @computed
  get nextAnnualAutoRenewDate() {
    return moment(this.get('next_annual_auto_renew_date'));
  }

  @computed
  get currency() {
    return this.get('price').currency.code;
  }

  @computed
  get fee() {
    return parseMoney(this.get('fee'));
  }

  @computed
  get isActive() {
    return this.get('status') === 'active';
  }

  @computed
  get membershipTypes() {
    if (!this.orgUnit || !this.orgUnit.membership_types) return null;

    return new MembershipTypes(this.orgUnit.membership_types);
  }
}
