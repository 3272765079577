import { TotemModel } from 'models/totem';

export class AppState extends TotemModel {
  urlRoot() {
    return '';
  }

  get getOrgID() {
    const urlParts = new URL(document.location).pathname.split('/');
    let id = urlParts[3];
    if (id && urlParts[2] != 'org') id = null; // use id in url IFF /org route.
    if (!id && this.has('currentLeadership') && this.get('currentLeadership')) {
      // case where user has a leadership, but they are on the member ux (org id not in url)
      // still need access to current org_id
      return this.get('currentLeadership').org_unit.id;
    }
    return id;
  }
}

export default new AppState({
  loaded: false,
  currentLeadership: null,
  currentOrg: null
});
