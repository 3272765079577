import { computed, observable, toJS } from "mobx";
import { studentNonMember } from "models/lookup";
import { TotemCollection } from "models/totem";
import { User } from "models/user";


export class CartUsers extends TotemCollection<CartUser> {
  model() {
    return CartUser;
  }

  @computed
  get paidUsers() {
    return this.models.filter((u) => !u.unpaidUser);
  }
}

export class CartUser extends User {
  constructor(attributes) {
    super(
      Object.assign(
        {
          metadata: {
            extra_fields: {},
          },
        },
        attributes
      )
    );
  }

  @computed
  get membershipType() {
    return this.get('membership_type');
  }

  @computed
  get membershipTypeExtraFields() {
    return this.membershipType && this.membershipType.extraFields;
  }

  @computed
  get membershipTypeId() {
    return this.membershipType && this.membershipType.get('id');
  }

  @computed
  get membershipTypeName() {
    return this.membershipType && this.membershipType.get('name');
  }

  @computed
  get hasValidMembersipType() {
    if (!this.membershipType) return false;
    if (this.membershipType.extraFields.length > 0)
      return this.membershipType.extraFields.every((extra) => extra.required ? !!this.metadata.extra_fields[extra.name] : true
      );
    return true;
  }

  @computed
  get metadata() {
    return this.get('metadata');
  }

  @computed
  get price() {
    return this.membershipType && this.membershipType.price;
  }

  @computed
  get unpaidUser() {
    return this.membershipTypeName == studentNonMember.get('name');
  }

  toJS() {
    const attrs = observable.map({
      id: this.get('id'),
      membership_id: this.get('membership_id'),
      membership_type_id: this.membershipTypeId,
      metadata: toJS(this.metadata),
      name: this.get('name'),
    });

    return toJS(attrs, { exportMapsAsObjects: true });
  }
}
