import { computed } from 'mobx';

import formatCurrency from 'models/concerns/format_currency';
import parseMoney from 'models/concerns/parse_money';
import { TotemCollection, TotemModel } from 'models/totem';

export class Lookup extends TotemModel {
  @computed
  get extraFields() {
    const extraFields = this.get('extra_fields') ? this.get('extra_fields').toJS() : [];
    extraFields.push({
      name: 'Note',
      required: false,
      type: 'string',
    });
    return extraFields;
  }

  get displayWeight() {
    return this.get('display_weight');
  }
}

export class Lookups extends TotemCollection<Lookup> {
  urlRoot() {
    return 'lookups';
  }

  model() {
    return Lookup;
  }

  @computed
  get asOptions() {
    return this.models.map((m) => ({
      name: m.get('name'),
      value: m.get('id'),
    }));
  }

  @computed
  get asRichOptions(): { name: string; value: TotemModel; label?: string }[] {
    const ClassName = this.model();
    return this.models.map((m) => ({
      name: m.get('name'),
      value: new ClassName(m.toJS()), // Separate from collection to avoid RangeError
    }));
  }

  asRichOptionsWithLabel(label) {
    return this.asRichOptions.map((m) => {
      m.label = m.value.get(label);
      return m;
    });
  }
}

export default new Lookups();

class MembershipType extends Lookup {
  @computed
  get currency() {
    return this.get('price').currency.code;
  }

  @computed
  get price() {
    return parseMoney(this.get('price'));
  }

  @computed
  get formattedPrice() {
    return formatCurrency(this.price, this.currency);
  }
}

export class MembershipTypes extends Lookups {
  model() {
    return MembershipType;
  }

  fetch() {
    return super.fetch({
      data: {
        filter: {
          type: 'MembershipType',
        },
      },
    });
  }
}
export const membershipTypes = new MembershipTypes();

class LeadershipPositions extends Lookups {
  fetch() {
    return super.fetch({
      data: {
        filter: {
          type: 'LeadershipPosition',
        },
        page: {
          size: 100,
        },
      },
    });
  }
}
export const leadershipPositions = new LeadershipPositions();

class OrgScopes extends Lookups {
  fetch() {
    return super.fetch({
      data: {
        filter: {
          type: 'OrgScope',
        },
      },
    });
  }

  get councilDistrictIDs() {
    const ids = this.models
      .slice()
      .filter((scope) => {
        const name = scope.get('name');
        return name == 'Council' || name == 'District';
      })
      .map((scope) => {
        return scope.get('id');
      });
    return ids;
  }
}
export const orgScopes = new OrgScopes();

class MemberGoalTypes extends Lookups {
  fetch() {
    return super.fetch({
      data: {
        filter: {
          type: 'MemberGoalType',
        },
      },
    });
  }
}

export const memberGoalTypes = new MemberGoalTypes();

export const studentNonMember = new MembershipType({
  id: 'f6d019d4-5b33-4c00-b166-62f0c8e4036d',
  type: 'MembershipType',
  name: 'Student (Non-Member)',
  extra_fields: [
    {
      name: 'Grade',
      type: 'string',
      required: true,
    },
    {
      name: 'Teacher',
      type: 'string',
      required: true,
    },
  ],
  price: {
    amount: '0.00',
    currency: {
      code: 'USD',
      symbol: '$',
    },
    formatted_amount: '$0.00',
  },
});
