import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import { FormFeedback } from 'reactstrap';

import currentUser from 'models/current_user';
import RowCol from 'shared/row_col';
import { errorEvent } from 'shared/analytics';
import { onSavePayMethod } from './onSavePayMethodFromStripeSource';
import { parseStripeError } from '../util/parseStripeError';

export { onSavePayMethod };

const CardElementStyle = {
  base: {
    color: '#32325d',
    fontFamily: 'monospace',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4',
    },
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
  },
};

type AddPayMethodFormProps = {
  elements: any;
  stripe: any;
  beforeSubmitCallback?: (...args: any[]) => any;
  callback?: (...args: any[]) => any;
  children: any;
  className?: string;
  id?: string;
  promiseCallback?: (...args: any[]) => any;
  stripeInputChanged?: (...args: any[]) => any;
};

class AddPayMethodForm extends React.Component<AddPayMethodFormProps> {
  state = { errorMessage: null, submitInProgress: false };
  form = React.createRef<HTMLFormElement>();

  setErrorMessage = (errorMessage: string) => this.setState({ errorMessage });

  setSubmitInProgress = (setSubmitInProgress: boolean) => this.setState({ setSubmitInProgress });

  handleSubmit = (resolve, reject) => {
    const { stripe, elements } = this.props;

    this.setSubmitInProgress(true);

    const cardElement = elements.getElement(CardElement);

    stripe
      .createSource(cardElement, {
        owner: {
          name: currentUser.get('name'),
          email: currentUser.get('email'),
        },
        type: 'card',
      })
      .then(({ source, error }) => {
        if (error) {
          this.setErrorMessage(error.message);
          reject && reject(error.message);
        } else {
          onSavePayMethod(source)
            .then((success) => resolve && resolve(success))
            .catch((e) => {
              console.error(e);
              const msg =
                e.stripe_id ||
                parseStripeError(e) ||
                'There was an error processing your request. Our staff has been alerted.';
              this.setErrorMessage(msg);
              // GA error event
              errorEvent('join error', msg);
              reject && reject(e.message);
            });
        }
      })
      .catch((e) => console.log('source create error', e));
  };

  onSubmit = (e?: any) => {
    e && e.preventDefault();

    const { beforeSubmitCallback, callback, promiseCallback } = this.props;

    const { submitInProgress } = this.state;

    if (!submitInProgress) {
      beforeSubmitCallback && beforeSubmitCallback();

      const cleanUp = () => this.setSubmitInProgress(false);

      if (promiseCallback) {
        promiseCallback(new Promise((resolve, reject) => this.handleSubmit(resolve, reject)))
          .catch(() => null)
          .finally(() => cleanUp());
      } else {
        this.handleSubmit((success) => {
          callback && callback(success);
          cleanUp();
        }, cleanUp);
      }
    }
  };

  onChange = (payload) => {
    const { stripeInputChanged } = this.props;
    this.setErrorMessage(null);
    typeof stripeInputChanged === 'function' && stripeInputChanged(payload);
  };

  render() {
    const {
      beforeSubmitCallback,
      callback,
      children,
      promiseCallback,
      stripeInputChanged,
      stripe,
      ...rest
    } = this.props;

    const { errorMessage } = this.state;

    return (
      <form ref={this.form} onSubmit={this.onSubmit} {...rest}>
        <RowCol colClass="stripe-card-element">
          <CardElement options={{ style: CardElementStyle }} onReady={(el) => el.focus()} onChange={this.onChange} />
        </RowCol>
        <RowCol>
          <FormFeedback className="clearfix text-center" style={{ display: 'inherit' }}>
            {errorMessage}
          </FormFeedback>
        </RowCol>
        {children}
      </form>
    );
  }
}

export default AddPayMethodForm;
