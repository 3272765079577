function isHash(arg: any): boolean {
  return arg !== null && typeof arg === 'object' && !Array.isArray(arg);
}

export function parseStripeError(err: any): string {
  // ensure err is hash && err has payload hash attribute
  if (isHash(err) && isHash(err?.payload))
    return Object.keys(err.payload)
      .filter((k) => Array.isArray(err.payload[k])) // expect err.payload to have format {[key: string]: string[]}
      .map((k) => err.payload[k].join(' '))
      .join(' ');

  return null;
}
