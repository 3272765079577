import React from 'react';

import { StandardFormDisabledContext, StandardFormModelContext } from './StandardForm';
import { StandardFieldContent } from './StandardFieldContent';
import { StandardFieldProps as SharedStandardFieldProps } from './types';

type StandardFieldProps = SharedStandardFieldProps & {
  // innerRef?: React.RefObject<any>;
  innerRef?: (input: StandardFieldContent) => void;
};

// = ({ innerRef, ...inputProps }) => {
export class StandardField extends React.Component<StandardFieldProps, {}> {
  // input = React.createRef<StandardFieldContent>();

  render() {
    let { innerRef, ...inputProps } = this.props;

    return (
      <StandardFormModelContext.Consumer>
        {(formModel) => (
          <StandardFormDisabledContext.Consumer>
            {(formDisabled) => (
              <StandardFieldContent
                {...inputProps}
                formModel={formModel}
                formDisabled={formDisabled}
                ref={(input) => {
                  if (innerRef) {
                    // innerRef = input;
                    innerRef(input);
                  }
                  // else {
                  //   this.input = input;
                  // }
                }}
              />
            )}
          </StandardFormDisabledContext.Consumer>
        )}
      </StandardFormModelContext.Consumer>
    );
  }
}
