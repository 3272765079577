import React, { Component, FC } from 'react';
import { Alert, Card, CardHeader, Container, Row } from 'reactstrap';
import { observer } from 'mobx-react';
import { Redirect, Route, Switch } from 'react-router-dom';

import cart from 'models/cart';
import { CartView } from './cart_view';
import { CheckoutView } from './checkout_view';
import { JoinPTA } from 'models/applets/join_pta';
import { OrgHeader } from 'dashboard/nav/org_header';
import { RegistrationView } from './registration_view';

class JoinPTALayout extends Component {
  applet: JoinPTA;

  static NAV_MAP = [
    { path: '/register', component: RegistrationView, context: 'Register' },
    {
      path: '/cart',
      component: CartView,
      context: 'Join & Donate',
    },
    { path: '/checkout', component: CheckoutView, context: 'Complete Payment' },
  ];

  constructor(props) {
    super(props);

    this.applet = new JoinPTA({ id: window['APPLET_ID'] });
    this.applet.ensureFetched().then(() => cart.items.set([{ applet: this.applet }]));
  }

  render() {
    return (
      <Container className="mt-md-2">
        <Row>
          <JoinPTALayoutView applet={this.applet} NAV_MAP={JoinPTALayout.NAV_MAP} />
        </Row>
      </Container>
    );
  }
}
export default JoinPTALayout;

const JoinPTALayoutView: FC<{
  applet: JoinPTA;
  NAV_MAP: { path: string; component: any; context: string }[];
}> = observer(({ applet, NAV_MAP }) => {
  const renderSwitch = (navAttribute) => {
    return (
      <Switch>
        {NAV_MAP.map((nav, i) => (
          <Route
            key={i}
            exact
            path={nav.path}
            render={(props) => {
              const Attr = nav[navAttribute];
              if (typeof Attr == 'function') {
                return <Attr applet={applet} {...props} />;
              } else return Attr;
            }}
          />
        ))}
        {navAttribute === 'context' && <Redirect to="/register" />}
      </Switch>
    );
  };

  if (applet.isRequest('fetching')) return null;

  const org = applet.get('org_unit') || {};

  return (
    <Card className="w-100">
      <CardHeader className="py-0">
        {org && (
          <OrgHeader
            logoUrl={org.logo_url}
            logoHeight={64}
            logoWidth={64}
            orgName={org.common_name_or_name}
            orgDetail={`${org.city}, ${org.state}`}
          />
        )}
      </CardHeader>
      {window['BANNER'] && (
        <CardHeader className="p-0">
          <Alert className="m-0" color="primary">
            {window['BANNER']}
          </Alert>
        </CardHeader>
      )}

      <CardHeader className="card-subhead">{renderSwitch('context')}</CardHeader>
      {renderSwitch('component')}
    </Card>
  );
});
