/* eslint-disable react/prop-types */
import renderMobxRestApp from 'render_mobx_rest_app'; // must always come first!

import React from 'react';

import { loadStripe } from '@stripe/stripe-js';
import { Route, Router, Switch } from 'react-router-dom';

import '../../applets/join_pta/join_pta_styles.scss';
import JoinPTALayout from 'applets/join_pta/layout';
import utm from 'shared/site/utm'; // eslint-disable-line no-unused-vars

import '../hmr'

export const stripePromise = loadStripe(window['STRIPE_PUBLIC_KEY']);

const JoinPTALayoutWithRouter = (props) => (
  <Router history={props.history}>
    <Switch>
      <Route component={JoinPTALayout} />
    </Switch>
  </Router>
);

renderMobxRestApp({
  RootComponent: JoinPTALayoutWithRouter,
  basename: window['BASE_PATH'],
  stripePromise,
});
