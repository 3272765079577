import * as React from 'react';
import { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { isValid, parseTeachers } from 'models/settings/teacher_lookup';

const Option = (props: any) => {
  return (
    <span className="notranslate">
      <components.Option {...props} />
    </span>
  );
};

interface PropsType {
  // history: any
  id?: string;
  isDisabled?: boolean;
  menuIsOpen?: boolean;
  options: Array<{ label: string; value: string }>;
  onChange: (val: string) => void;
  styles?: { [key: string]: any };
  value: string;
}

interface StateType {
  // filter: string;
}

export class TeacherSelect extends React.Component<PropsType, StateType> {
  state: Readonly<StateType> = {
    // filter: ''
  };

  isValidNewOption = (inputValue: string): boolean => {
    return isValid(inputValue) && parseTeachers(inputValue).length <= 1 && inputValue != '';
  };

  render() {
    let { id, options: optionProps, onChange, value, ...rest } = this.props;

    if (!optionProps.find((o) => o.value == 'Unknown'))
      optionProps = [{ label: 'Unknown', value: 'Unknown' }, ...optionProps];

    // ensure 'Unknown' options comes first
    optionProps = optionProps.sort((o1, o2) => (o1.value == 'Unknown' ? -1 : o2.value == 'Unknown' ? 1 : 0));

    const options: Array<{ label: any; value: string }> = id
      ? optionProps.map((o) => {
          const optionID = `${id}-teacher-select-option-${o.label}`;
          return {
            value: o.value,
            label: <div id={optionID}>{o.label}</div>,
          };
        })
      : optionProps;

    return (
      <div>
        <CreatableSelect
          isClearable
          placeholder={
            <span>
              <span>Teacher (Last Name only)</span>
            </span>
          }
          components={{ Option }}
          options={options}
          formatCreateLabel={(inputValue) => <span className="notranslate">{`Select '${inputValue}'`}</span>}
          isValidNewOption={this.isValidNewOption}
          onChange={(newValue) => {
            const value = newValue ? newValue.value : null;
            if (onChange) onChange(value);
          }}
          onInputChange={(newValue: any, { action }) => {
            // get it to act like a text input
            if (onChange && action == 'input-change' && this.isValidNewOption(newValue)) onChange(newValue);
          }}
          value={value ? { label: value, value: value } : null}
          defaultValue={
            value
              ? options.find((o) => o.value == value) || {
                  label: value,
                  value: value,
                }
              : null
          }
          {...rest}
          classNamePrefix={id}
          // getNewOptionData={(inputValue, optionLabel) => inputValue.trim()}
        />
        <div style={{ display: 'flex' }}>
          <small style={{ marginLeft: 'auto' }}>Last name only</small>
        </div>
      </div>
    );
  }

  // options() {
  //   let result = [{ label: 'Smith', value: 'Smith' }, { label: 'Micale', value: 'Micale' }];
  //   result.push({ label: 'Smith', value: 'Smith' });
  //   result.push({ label: 'Micale', value: 'Micale' });
  //   result.push({ label: 'Smith', value: 'Smith' });
  //   result.push({ label: 'Micale', value: 'Micale' });
  //   result.push({ label: 'Smith', value: 'Smith' });
  //   result.push({ label: 'Micale', value: 'Micale' });
  //   result.push({ label: 'Smith', value: 'Smith' });
  //   result.push({ label: 'Micale', value: 'Micale' });
  //   result.push({ label: 'Smith', value: 'Smith' });
  //   result.push({ label: 'Micale', value: 'Micale' });
  //   result.push({ label: 'Smith', value: 'Smith' });
  //   result.push({ label: 'Micale', value: 'Micale' });
  //   result.push({ label: 'Smith', value: 'Smith' });
  //   result.push({ label: 'Micale', value: 'Micale' });
  //   result.push({ label: 'Smith', value: 'Smith' });
  //   result.push({ label: 'Micale', value: 'Micale' });
  //   return result;
  // }
}
