import React from 'react';
import { Button, Table } from 'reactstrap';
import { Drawer } from 'antd';
import { observer } from 'mobx-react';
import { Request } from 'mobx-rest';

import { DonationLevel, DonationLevels } from 'models/leaders/donation_level';
import { StandardField, StandardForm } from 'shared/standard_field';

type DonationLevelEditorFormProps = {
  levels: DonationLevels;
  onCreateLevel: (level: DonationLevel) => Request;
  onDeleteLevel: (level: DonationLevel) => Request;
  onEditLevel: (level: DonationLevel) => Request;
};

type DonationLevelEditorFormState = {
  visible: boolean;
  level: DonationLevel;
};

@observer
export default class DonationLevelEditorForm extends React.Component<
  DonationLevelEditorFormProps,
  DonationLevelEditorFormState
> {
  state = { visible: false, level: new DonationLevel({}) };

  showDrawer = () => this.setState({ visible: true });

  onClose = () => {
    const { level } = this.state;
    level.reset({});
    this.setState({ level, visible: false });
  };

  onDeleteLevel = () => {
    const confirmed = confirm(
      `Are you sure you want to delete the ${this.state.level.headline} donation level? This action cannot be undone.`
    );
    if (confirmed) {
      const request = this.props.onDeleteLevel(this.state.level);
      request.promise.then(this.onClose).catch((e) => console.log(e));
    }
  };

  onEditLevel = (dLevel) => {
    const { level } = this.state;
    level.set(dLevel.toJS());
    this.setState({ level });
    this.showDrawer();
  };

  onSubmit = () => {
    const dLevel = this.state.level;
    let request = dLevel.get('id') ? this.props.onEditLevel(dLevel) : this.props.onCreateLevel(dLevel);
    request.promise.then(this.onClose).catch((e) => console.log(e));
  };

  renderEditButton = (dLevel) => {
    return (
      <Button color="primary" outline size="sm" onClick={() => this.onEditLevel(dLevel)}>
        Edit
      </Button>
    );
  };
  renderDrawerForm = () => {
    const maxCharsHeadline = 20;
    const maxCharsDescription = 150;
    return (
      <StandardForm model={this.state.level} onSubmit={this.onSubmit}>
        <div>Headline</div>
        <StandardField
          noLabel
          name="headline"
          maxLength={maxCharsHeadline}
          hint={`max ${maxCharsHeadline} chars`}
          required
        />
        <div>Amount</div>
        <StandardField type="currency" noLabel name="amount" required />
        <div>Description</div>
        <StandardField
          type="textarea"
          maxLength={maxCharsDescription}
          hint={`max ${maxCharsDescription} chars`}
          noLabel
          name="description"
        />
        <div className="d-flex justify-content-end">
          <Button size="sm" color="primary" type="submit">
            Save
          </Button>
        </div>
        <Button color="link" onClick={this.onClose}>
          Cancel
        </Button>
        {this.state.level.get('id') && (
          <Button color="link" onClick={this.onDeleteLevel}>
            Delete this option
          </Button>
        )}
      </StandardForm>
    );
  };
  renderLevels = () => {
    return (
      <Table className="table-borderless" style={{ color: '#000000a6', marginLeft: '3rem', fontWeight: 'normal' }}>
        <thead>
          <tr>
            <th className="pl-0">Headline</th>
            <th>Description</th>
            <th>Amount</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {this.props.levels.models.map((dLevel, i) => {
            return (
              <tr key={i}>
                <td className="pl-0">{dLevel.headline}</td>
                <td className="d-inline-block text-truncate" style={{ maxWidth: '15ch' }}>
                  {dLevel.description}
                </td>
                <td>{dLevel.formattedAmount}</td>
                <td className="text-right">{this.renderEditButton(dLevel)}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };
  render() {
    return (
      <>
        <strong>DONATION LEVELS</strong>
        {this.renderLevels()}
        <Button outline size="sm" color="primary" onClick={this.showDrawer}>
          Add an Option
        </Button>
        <Drawer placement="right" onClose={this.onClose} visible={this.state.visible} width={400}>
          {this.renderDrawerForm()}
        </Drawer>
      </>
    );
  }
}
