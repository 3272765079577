import React from 'react';

import classNames from 'classnames';
import CreatableSelect from 'react-select/creatable';
import moment from 'moment';
import Select from 'react-select';
import { Col, FormFeedback, FormGroup, FormText, Input, Label } from 'reactstrap';
import { humanize } from 'inflection';
import { observer } from 'mobx-react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { DatePicker, Switch } from 'antd';

import { AddressSelector } from 'shared/organisms/address_selector';
import { CurrencyInput } from 'shared/currency_input';
import { NAME_REGEX_STRING } from 'models/user';
import { StandardFieldProps as SharedStandardFieldProps, SelectOptionType } from './types';
import { TotemModel } from 'models/totem/model';

import '../styles/form.vanilla.scss';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';

type StandardFieldContentProps = typeof StandardFieldContent.defaultProps & SharedStandardFieldProps & {};

@observer
export class StandardFieldContent extends React.Component<StandardFieldContentProps, {}> {
  static defaultProps = {
    labelColumns: 4,
  };

  isInvalid: boolean;
  model: TotemModel;
  options: SelectOptionType[];

  input: HTMLInputElement | HTMLTextAreaElement;
  typeahead = React.createRef<Typeahead>();

  constructor(props) {
    super(props);

    this.model = props.model || props.formModel;
    // Add placeholder to beginning of select options if provided
    if (props.type == 'select' && props.placeholder && props.options)
      this.options = [{ name: props.placeholder, value: 0, placeholder: true }, ...this.props.options];
    else this.options = props.options;
  }

  componentDidMount() {
    if (this.typeahead && this.typeahead.current) {
      this.input = this.typeahead.current.getInput();
    }
    this._setHtml5ValidityState();
  }

  componentDidUpdate() {
    this._setHtml5ValidityState();
  }

  _resetModelError() {
    const err = this.model.error;
    if (err && err.body[this.props.name]) err.body[this.props.name] = null;
  }

  _modelErrorMessage() {
    const err = this.model.error;
    if (err && err.body[this.props.name]) {
      return err.body[this.props.name][0];
    } else {
      return null;
    }
  }

  _setHtml5ValidityState() {
    if (!this.input) return;
    if (this.isInvalid) this.input.setCustomValidity('Invalid field.');
    else this.input.setCustomValidity('');
  }

  handleInputChange = (e) => {
    const { type, name, maxLength, onInputChanged } = this.props;

    let value;
    if (e && e.target) value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    else if (type === 'date') {
      // e is a moment object
      value = e ? e.format() : null;
    } else value = e;

    let patch;
    if (type === 'typeahead' && Array.isArray(value))
      // For typeaheads, when selecting an option, event value is an array
      patch = value[0];
    else if (type === 'select') {
      // For selects, value is the index of the option that was selected. This is to allow objects as options.
      patch = { [name]: this.options[value].value };
    } else patch = { [name]: value };

    if (maxLength && type === 'currency' && value.length > parseInt(maxLength)) return;
    this.model.set(patch);

    if (onInputChanged) onInputChanged(e);

    this._resetModelError();
  };

  render() {
    let {
      children,
      defaultErrorMessage,
      defaultValue,
      formDisabled,
      formGroupClassName,
      formModel,
      hint,
      inputColumns,
      labelColumns,
      model,
      name,
      noFormGroup,
      noLabel,
      noRow,
      noSerialize,
      onInputChanged,
      options,
      staticContent,
      type,
      reactSelectDefaultValue,
      label,
      ...inputProps
    } = this.props;

    const nameAttr = noSerialize ? `${name}:skip` : name;

    // Set a unique id in the format modelName_modelId_name
    const id = inputProps.id || `${this.model.constructor.name.toLocaleLowerCase()}_${this.model.id}_${name}`;
    inputProps.id = id;

    const displayLabel = label === null ? '' : label || humanize(name);

    const modelValue = this.model.get(name);

    let value;
    if (modelValue)
      if (type === 'select' && modelValue) {
        const modelId =
          modelValue.id ||
          // Bugfix for situation where .id isn't available, causing wrong dropdown value to be displayed temporarily when saving
          (modelValue.attributes ? modelValue.attributes.id : null);
        if (modelId)
          // Complex select, with objects as values
          value = this.options.findIndex((op) => op.value.id === modelId);
        else value = this.options.findIndex((op) => op.value === modelValue);
      } else value = modelValue;
    else if (defaultValue) value = defaultValue;
    else value = '';

    const clientErrorMessage = defaultErrorMessage;
    const modelErrorMessage = this._modelErrorMessage();
    const errorMessage = modelErrorMessage || clientErrorMessage;

    this.isInvalid = modelErrorMessage;

    const labelClasses = classNames({ required: inputProps.required });

    // Checkboxes need to have the label rendered after the input
    const labelFirst = type !== 'checkbox';

    // Typeaheads need to show the error message above the input because
    // the autocomplete suggestions appear below it and will cover it
    const errorFirst = type === 'typeahead';

    let labelNode;
    if (!noLabel)
      labelNode = (
        <Label md={labelFirst ? labelColumns : 0} for={id} className={labelClasses}>
          {displayLabel}
        </Label>
      );

    const { disabled } = inputProps;

    const formGroupProps = {
      disabled,
      errorFirst,
      errorMessage,
      formGroupClassName,
      hint,
      inputColumns,
      labelFirst,
      labelNode,
      noRow,
    };

    const input = ((type) => {
      switch (type) {
        case 'address':
          // hidden input needed b/c AddressSelector returns Address component
          // in static state, which is not an input tag
          return (
            <>
              {this.model.hasChanges() && <input type="hidden" name={nameAttr} value={value} />}
              <AddressSelector
                onChange={this.handleInputChange}
                value={value}
                // classNames={{ input: 'form-control' }}
                // innerRef={this.input}
                invalid={this.isInvalid}
                disabled={formDisabled || disabled}
                name={nameAttr}
                {...inputProps}
              />
            </>
          );
        case 'checkbox':
          inputProps.disabled = disabled;

          return (
            <Input
              name={nameAttr}
              type={type}
              checked={value}
              value={value}
              {...inputProps}
              onChange={this.handleInputChange}
              innerRef={(i) => (this.input = i)}
              invalid={this.isInvalid}
            />
          );
        case 'bootstrap-toggle':
          return (
            <div className="custom-control custom-switch">
              <input
                name={nameAttr}
                type="checkbox"
                className="custom-control-input"
                id="customSwitch1"
                value={value}
                checked={value}
                onChange={this.handleInputChange}
              ></input>
              <label className="custom-control-label" htmlFor="customSwitch1"></label>
            </div>
          );
        case 'currency':
          inputProps.disabled = disabled;
          return (
            <CurrencyInput
              name={nameAttr}
              value={value && (value.amount || value)}
              {...inputProps}
              onChange={this.handleInputChange}
              className="text-right"
              innerRef={(i) => (this.input = i)}
              errorMessage={errorMessage}
            />
          );
        case 'date': {
          const { className, placeholder, dateDisplayFormat, dateFormatter, ...rest } = inputProps;
          inputProps.disabled = disabled;

          if (value) value = dateFormatter ? dateFormatter(value) : moment(value);

          return (
            <DatePicker
              value={value || null}
              onChange={this.handleInputChange}
              className={classNames('form-control', className)}
              placeholder={placeholder}
              format={dateDisplayFormat}
              {...rest}
            />
          );
        }
        case 'name':
          inputProps.disabled = disabled;
          return (
            <Input
              name={nameAttr}
              // type={type}
              type="text"
              value={value}
              autoComplete="name"
              placeholder={inputProps.placeholder || 'First Last'}
              pattern={NAME_REGEX_STRING}
              {...inputProps}
              onChange={this.handleInputChange}
              innerRef={(i) => (this.input = i)}
              invalid={this.isInvalid}
            />
          );
        case 'email':
          inputProps.disabled = disabled;
          return (
            <Input
              name={nameAttr}
              type={type}
              value={value}
              placeholder={inputProps.placeholder || 'name@domain.com'}
              {...inputProps}
              onChange={this.handleInputChange}
              innerRef={(i) => (this.input = i)}
              invalid={this.isInvalid}
            />
          );
        case 'phone':
          inputProps.disabled = disabled;
          return (
            <Input
              name={nameAttr}
              type="tel"
              value={value}
              placeholder={inputProps.placeholder || '555-555-1234'}
              {...inputProps}
              onChange={this.handleInputChange}
              innerRef={(i) => (this.input = i)}
              invalid={this.isInvalid}
            />
          );
        case 'react-select':
          return (
            <Select
              placeholder={inputProps.placeholder}
              options={this.options.map((o) => {
                const id = `react-select-${nameAttr}-option-${o.label}`;
                return {
                  value: o.value,
                  label: <div id={id}>{o.label}</div>,
                };
              })}
              onChange={(item) => {
                if (inputProps.showErrorStyles) inputProps.resetErrorStyles();
                this.handleInputChange(item ? item.value : item);
              }}
              {...inputProps}
              ref={inputProps.reactSelectInnerRef}
              styles={{
                control: (provided) =>
                  inputProps.showErrorStyles
                    ? {
                        ...provided,
                        borderColor: 'red',
                      }
                    : provided,
              }}
              defaultValue={reactSelectDefaultValue}
              isDisabled={disabled}
            />
          );
        case 'react-select-creatable':
          // react-select doesn't handle validation yet https://github.com/JedWatson/react-select/issues/1453
          return (
            <CreatableSelect
              placeholder={inputProps.placeholder}
              options={this.options}
              onChange={(item) => {
                if (inputProps.showErrorStyles) inputProps.resetErrorStyles();
                this.handleInputChange(item ? item.value : item);
              }}
              onInputChange={(newValue, { action }) => {
                // get it to act like a text input
                if (action == 'input-change') this.handleInputChange(newValue);
              }}
              value={value ? { label: value, value: value } : null}
              {...inputProps}
              ref={inputProps.reactSelectInnerRef}
              styles={{
                control: (provided) =>
                  inputProps.showErrorStyles
                    ? {
                        ...provided,
                        borderColor: 'red',
                      }
                    : provided,
              }}
              isDisabled={disabled}
            />
          );
        case 'select':
          inputProps.disabled = disabled;

          return (
            <Input
              type={type}
              value={value}
              name={nameAttr}
              onChange={(e) => this.handleInputChange(e)}
              innerRef={(i) => (this.input = i)}
              className={classNames(value && 'chosen', inputProps.className)}
              {...inputProps}
            >
              {this.options.map((op, key) => (
                <option key={key} value={op.placeholder ? '' : key} disabled={op.placeholder}>
                  {op.name}
                </option>
              ))}
            </Input>
          );
        case 'radio':
          return (
            <FormGroup tag="fieldset">
              {this.options.map((op, key) => (
                <FormGroup key={key} check>
                  <Label check>
                    <Input
                      defaultChecked={op.value == this.model.get(name)}
                      onClick={() => this.handleInputChange(op.value)}
                      type={type}
                      name={name}
                      invalid={this.isInvalid}
                      disabled={disabled}
                    />{' '}
                    {op.label}
                  </Label>
                </FormGroup>
              ))}
            </FormGroup>
          );
        case 'toggle': {
          const { containerStyle, ...toggleProps } = inputProps;
          toggleProps.disabled = disabled;
          return (
            <Switch
              checked={value}
              checkedChildren={<span>YES</span>}
              unCheckedChildren={<span>NO</span>}
              onChange={(val) => {
                this.model.set({ [name]: val });
                if (onInputChanged) {
                  onInputChanged(val);
                }
              }}
              {...toggleProps}
              disabled={disabled}
            />
          );
        }
        case 'typeahead': {
          // Pull out all props intended for Typeahead
          // to avoid passing them thru to input
          const {
            // a11yNumResults,
            // a11yNumSelected,
            // align,
            // allowNew,
            autoFocus,
            // bodyContainer,
            bsSize,
            // caseSensitive,
            // clearButton,
            // defaultInputValue,
            // defaultSelected,
            // disabled,
            // dropup,
            emptyLabel,
            // filterBy,
            flip,
            highlightOnlyResult,
            // ignoreDiacritics,
            // isLoading,
            labelKey,
            // maxHeight,
            // maxResults,
            // menuId,
            minLength,
            // multiple,
            // newSelectionPrefix,
            onBlur,
            // onChange,
            // onFocus,
            // onInputChange,
            // onKeyDown,
            // onMenuHide,
            // onMenuShow,
            // onPaginate,
            // options,
            // paginate,
            // paginationText,
            placeholder,
            // renderMenu,
            // renderMenuItemChildren,
            // renderToken,
            // selected,
            selectHintOnEnter,
            ...realInputProps
          } = inputProps;

          inputProps.disabled = disabled;

          return (
            <Typeahead
              defaultInputValue={value}
              // Typeahead options need to be in a format that can be directly set on a model, ie: {name: 'Joe'}
              options={this.options}
              {...inputProps}
              inputProps={{ name: nameAttr, ...realInputProps }}
              onChange={this.handleInputChange}
              onInputChange={(value, event) => this.handleInputChange(event)}
              ref={this.typeahead}
            />
          );
        }
        case 'static':
          return staticContent;
        default:
          inputProps.disabled = disabled;

          return (
            <Input
              name={nameAttr}
              type={type}
              value={value}
              {...inputProps}
              onChange={this.handleInputChange}
              innerRef={(i) => (this.input = i)}
              invalid={this.isInvalid}
            />
          );
      }
    })(type);
    if (noFormGroup)
      return (
        <>
          {input}
          {children}
        </>
      );
    return (
      <StandardFormGroup {...formGroupProps}>
        {input}
        {children}
      </StandardFormGroup>
    );
  }
}
type StandardFormGroupProps = {
  disabled?: boolean;
  errorFirst?: boolean;
  errorMessage?: string;
  formGroupClassName?: string;
  hint?: string;
  inputColumns?: number;
  labelFirst: boolean;
  labelNode?: JSX.Element;
  noRow?: boolean;
};
const StandardFormGroup: React.SFC<StandardFormGroupProps> = ({
  children,
  disabled,
  errorFirst,
  errorMessage,
  formGroupClassName,
  hint,
  inputColumns,
  labelFirst,
  labelNode,
  noRow,
}) => {
  const inputNode = (
    <>
      {children}
      {errorMessage && <FormFeedback className="standard-form__input-feedback">{errorMessage}</FormFeedback>}
      {hint && <FormText className="standard-form__input-feedback">{hint}</FormText>}
    </>
  );
  return (
    <FormGroup className={formGroupClassName} row={!noRow} disabled={disabled}>
      {labelFirst && labelNode}
      {noRow ? (
        <>
          {inputNode}
          {!labelFirst && labelNode}
        </>
      ) : (
        <Col
          md={inputColumns}
          className={`${errorFirst ? 'd-flex flex-column-reverse' : ''}
                      ${errorMessage ? 'standard-form__input--error' : ''}`}
        >
          {inputNode}
          {!labelFirst && labelNode}
        </Col>
      )}
    </FormGroup>
  );
};
