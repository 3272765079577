import download from 'downloadjs-next';
import moment from 'moment';
import { apiClient } from 'mobx-rest';
import { Parser as Json2csvParser } from 'json2csv';
import { pluralize, titleize } from 'inflection';

export function convertDatesToRecognizedExcelFormats(models) {
  models = models.map((model) => {
    return singleModelDateConversion(model);
  });
  return models;
}

export function singleModelDateConversion(model) {
  for (const [k, v] of Object.entries(model)) {
    if (k.endsWith('_at') || k.endsWith('_date') || k === 'date') {
      if (v) model[k] = moment(v).format('YYYY/MM/DD HH:mm');
    }
  }
  return model;
}

function generateFieldTitle(field, value) {
  const fieldName = field.replace('jsonb.', '');
  const obj = {
    value: fieldName,
  };
  if (value) obj.label = value;
  else obj.label = titleize(fieldName);
  return obj;
}

function generateFieldTitles(fields, csvIgnoreFields) {
  if (Array.isArray(fields))
    return fields
      .filter((field) => !csvIgnoreFields || !csvIgnoreFields.includes(field))
      .map((field) => generateFieldTitle(field, null));
  else
    return Object.keys(fields)
      .filter((field) => !csvIgnoreFields || !csvIgnoreFields.includes(field))
      .map((field) => generateFieldTitle(field, fields[field]));
}

export function exportToExcel({
  csvIgnoreFields = [],
  fields,
  fileName,
  resourceName,
  pageSize = 65536,
  dataFilter = null,
  collection,
  urlParams = {},
  queryParams = {},
  onNotifyAsyncExportFailure = null,
  onNotifyAsyncExportSuccess = null,
}) {
  const url = collection.urlRoot();
  urlParams = Object.assign(
    {
      page: { size: pageSize },
      fields: { [resourceName]: [] },
      include: [],
      export: true,
    },
    urlParams
  );
  // process fields
  Object.keys(fields).forEach((key) => {
    if (key.startsWith('jsonb.')) {
      const [, field] = key.split('.');
      if (!urlParams.fields[resourceName].includes(field)) urlParams.fields[resourceName].push(field);
    } else if (key.includes('.')) {
      // ASSOCIATION
      const [association, associationAttr] = key.split('.');

      // add to included associations
      if (!urlParams.include.includes(association)) urlParams.include.push(association);
      if (!urlParams.fields[resourceName].includes(association)) urlParams.fields[resourceName].push(association);

      const associationPlural = pluralize(association);

      // add to sparse fields
      if (urlParams.fields[associationPlural]) urlParams.fields[associationPlural].push(associationAttr);
      else urlParams.fields[associationPlural] = [associationAttr];
    } else {
      // MAIN RESOURCE
      urlParams.fields[resourceName].push(key);
    }
  });

  // join included associations and sparse fields into comma separated strings
  Object.keys(urlParams.fields).forEach((fieldKey) => {
    urlParams.fields[fieldKey] = urlParams.fields[fieldKey].join(',');
  });
  urlParams.include = urlParams.include.join(',');

  if (queryParams.filter) urlParams.filter = queryParams.filter;
  // create fields arg for Json2csvParser
  const csvFields = generateFieldTitles(fields, csvIgnoreFields);
  urlParams.csv_fields = csvFields;

  if (collection.meta && collection.meta.total > 500) {
    urlParams.async_export = true;
    const { promise } = apiClient().get(url, urlParams);
    promise
      .then(() => {
        if (onNotifyAsyncExportSuccess)
          onNotifyAsyncExportSuccess(
            'Due to the size of your export, you will receive an email when the export is ready to be downloaded'
          );
      })
      .catch(() => {
        if (onNotifyAsyncExportFailure)
          onNotifyAsyncExportFailure(
            'You already have an export of this type in process. Please wait until it is completed before requesting another.'
          );
      });
  } else {
    urlParams.sync_export = true;
    const { promise } = apiClient().get(url, urlParams);
    promise.then((success) => {
      dataToCSV({
        data: success.models,
        csvFields: csvFields,
        dataFilter: dataFilter,
        fileName: fileName,
      });
    });
  }
}

export function dataToCSV({ data, csvFields, dataFilter, excludeDate = false, fileName }) {
  const json2csvOpts = {};
  if (csvFields) json2csvOpts['fields'] = csvFields;
  else json2csvOpts['fields'] = generateFieldTitles(Object.keys(data[0]));
  const json2csvParser = new Json2csvParser(json2csvOpts);

  const deepCopyData = JSON.parse(JSON.stringify(data));
  let models;
  if (dataFilter) {
    models = dataFilter(deepCopyData);
  } else {
    models = convertDatesToRecognizedExcelFormats(deepCopyData);
  }
  const csv = json2csvParser.parse(models);
  const date = moment().format('YYYY/MM/DD');
  fileName = `${fileName}${!excludeDate ? `.${date}` : ''}.csv`;
  download(csv, fileName, 'text/csv;charset=utf-8');
}

export function moneyDataFilter(models) {
  return models.map((model) => {
    model.amount = model.amount.formatted_amount;
    model = singleModelDateConversion(model);
    return model;
  });
}

export function serializeForm(form) {
  const serialized = {};
  const formData = new FormData(form);

  formData.forEach((value, key) => {
    // ensure booleans are parsed
    if (value === 'true') value = true;
    else if (value === 'false') value = false;
    serialized[key] = value;
  });

  // handle unchecked checkboxes -- set false
  const inputs = form.elements;
  for (let i = 0; i < inputs.length; i++) {
    const input = inputs[i];
    const { nodeName, type, name, checked } = input;
    if (nodeName === 'INPUT' && type === 'checkbox' && !checked) serialized[name] = false;
  }

  return serialized;
}
