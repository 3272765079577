import * as React from 'react';
import Select from 'react-select';

interface PropsType {
  id?: string;
  onChange: (val: string) => void;
  defaultInputValue: string;
  styles?: { [key: string]: any };
}

interface StateType {
  // filter: string;
}

export class GradeSelect extends React.Component<PropsType, StateType> {
  state: Readonly<StateType> = {
    // filter: ''
  };

  render() {
    const { id, defaultInputValue, onChange, ...rest } = this.props;
    const options = this.gradeOptions();
    let defaultValue = options.find((o) => o.value == defaultInputValue);

    return (
      <Select
        placeholder="Grade"
        options={options}
        onChange={(value) => {
          if (onChange) onChange(value.value);
        }}
        defaultValue={defaultValue}
        isSearchable={false}
        classNamePrefix={id}
        {...rest}
      />
    );
  }

  gradeOptions() {
    const { id } = this.props;
    let result: { label: string | JSX.Element; value: string }[] = [
      { label: 'Pre-K', value: 'Pre-K' },
      { label: 'TK', value: 'TK' },
      { label: 'Kindergarten', value: 'Kindergarten' },
    ];

    const firstUp = Array.from(new Array(12), (x, i) => i + 1);

    result = result.concat(
      firstUp.map((grade) => {
        return {
          value: grade.toString(),
          label: `${getNumberWithOrdinal(grade)} grade`,
        };
      })
    );

    result.push({ label: 'Other', value: 'Other' });

    // if (id)
    //   result = result.map((option) => {
    //     option.label = <div id={`${id}-option-${option.label}`}>{option.label}</div>;
    //     return option;
    //   });

    return result;
  }
}

export function getNumberWithOrdinal(n) {
  let s = ['th', 'st', 'nd', 'rd'],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}
