import { computed, observable, toJS } from "mobx";
import { AcceptsDonation } from "models/accepts_donation";
import { TotemCollection } from "models/totem";
import { CartUsers } from "./CartUsers";

export class CartItems extends TotemCollection<CartItem> {
  model() {
    return CartItem;
  }
}

export class CartItem extends AcceptsDonation {
  constructor(attributes) {
    super(
      Object.assign(
        {
          users: new CartUsers(),
        },
        attributes
      )
    );
  }

  @computed
  get applet() {
    return this.get('applet');
  }

  get currency() {
    return this.applet.currency;
  }

  @computed
  get fees() {
    return this.numPaidUsers * this.applet.fee + this.cleanDonationFee;
  }

  @computed
  get subtotal() {
    return this.users.models.reduce((acc, user) => acc + user.price, 0) + this.cleanDonation;
  }

  @computed
  get total() {
    return this.subtotal + this.fees;
  }

  @computed
  get numPaidUsers() {
    return this.users.paidUsers.length;
  }

  @computed
  get numUsers() {
    return this.users.length;
  }

  @computed
  get users() {
    return this.get('users');
  }

  toJS() {
    const attrs = observable.map({
      applet_id: this.applet.get('id'),
      donation: this.formattedDonationPlusFee,
      users: this.users.toJS(),
      donation_meta: this.donationMeta,
    });
    return toJS(attrs, { exportMapsAsObjects: true });
  }
}
