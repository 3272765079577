import moment from 'moment';
import { apiClient } from 'mobx-rest';
import { computed } from 'mobx';

import parseMoney from 'models/concerns/parse_money';
import { Lookup } from 'models/lookup';
import { TotemModel, TotemCollection } from 'models/totem';
import { User } from 'models/user';

export class BaseMembership extends TotemModel {
  urlRoot() {
    return null;
  }

  @computed
  get associatedMembers(): TotemCollection<BaseMembership> {
    throw 'Must define associatedMembers getter';
  }

  get isNonMember() {
    return this.get('membership_type').name == 'Student (Non-Member)';
  }

  get isRoot() {
    return this.get('parent_id') == null;
  }

  get status() {
    return this.get('status');
  }

  get pastExpirationDate() {
    return this.get('past_expiration_date');
  }

  get isRenewable() {
    return this.isExpired || (this.isArchived && this.pastExpirationDate);
  }

  @computed
  get associatedPrimaryMember() {
    if (this.isRoot) return this;
    else return this.associatedMembers.find((aM) => aM.isRoot);
  }

  get isArchived() {
    return this.status == 'archived';
  }

  get isActive() {
    return this.status === 'active';
  }

  get isExpired() {
    return this.status === 'expired';
  }

  @computed
  get lastDonationAmount() {
    return parseMoney(this.get('last_donation_amount'));
  }

  @computed
  get memberSince() {
    return moment(this.get('member_since_date')).format('l');
  }

  @computed
  get membershipType() {
    const mt = this.get('membership_type');
    if (!mt || mt instanceof Lookup) return mt;
    else return new Lookup(mt);
  }

  @computed
  get user() {
    return this.get('user') && new User(this.get('user'));
  }

  shared(media) {
    const { promise } = apiClient().get(`${this.urlRoot()}/${this.get('id')}/log/${media}`);
    return promise;
  }
  emailMembershipCard(data) {
    const { promise } = apiClient().post(`${this.urlRoot()}/${this.get('id')}/email_card`, data);
    return promise;
  }
  emailJoinLink(data) {
    const { promise } = apiClient().post(`${this.urlRoot()}/${this.get('id')}/email_join_link`, data);
    return promise;
  }
}
