import React from 'react';

import classNames from 'classnames';
import { Form } from 'reactstrap';
import { observer } from 'mobx-react';
import jquery from 'jquery';

import '../styles/form.vanilla.scss';
import { TotemModel } from 'models/totem/model';

export const StandardFormModelContext = React.createContext(null);
export const StandardFormDisabledContext = React.createContext(null);

const $ = jquery;

type StandardFormProps = typeof StandardForm.defaultProps & {
  autoComplete?: string;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  id?: string;
  inline?: boolean;
  model?: TotemModel;
  onSubmit?: (form?: HTMLFormElement) => void | Promise<any>;
  vanillaForm?: boolean;
};

type StandardFormState = {
  wasValidated: boolean;
};

@observer
export class StandardForm extends React.Component<StandardFormProps, StandardFormState> {
  static defaultProps = {
    vanillaForm: true,
    disabled: false,
  };

  form: HTMLFormElement;
  $form: JQuery<Element>;
  state = { wasValidated: false };

  _onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { onSubmit } = this.props;

    if (this.form.checkValidity() === false) {
      this.setState({ wasValidated: true });
      this.$form
        .find(':invalid')
        .first()
        .focus();
    } else {
      if (onSubmit) {
        const submitResult = onSubmit(this.form);
        if (submitResult && typeof submitResult.then === 'function')
          submitResult.then(() => this.setState({ wasValidated: false })).catch(() => null);
        else this.setState({ wasValidated: false }); // assume success
      }
    }
  };

  render() {
    const { className, vanillaForm, model, disabled, ...formProps } = this.props;
    let hasModelErrors = false;
    if (model && model.error != null) hasModelErrors = true;
    const formClassNames = classNames(
      className,
      { vanilla: vanillaForm },
      {
        'was-validated': this.state.wasValidated || hasModelErrors,
      },
      { 'disabled-form': disabled }
    );
    return (
      <Form
        {...formProps}
        onSubmit={this._onSubmit}
        noValidate
        className={formClassNames}
        innerRef={(i) => (this.form = i)}
      >
        <fieldset disabled={this.props.disabled}>
          <StandardFormModelContext.Provider value={model}>
            <StandardFormDisabledContext.Provider value={disabled}>
              {this.props.children}
            </StandardFormDisabledContext.Provider>
          </StandardFormModelContext.Provider>
        </fieldset>
      </Form>
    );
  }
  componentDidMount() {
    this.$form = $(this.form);
  }
}
