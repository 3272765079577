import PropTypes from 'prop-types';
import React from 'react';
import UserAvatar from 'react-user-avatar';
import qs from 'qs';

// import { resizeImageUrls } from 'shared/helpers';
function resizeImageUrls({ url, width, height, resizeFactor }) {
  if (!(url && width && height)) return url;
  width = `${Math.round(parseInt(width) * resizeFactor)}`; // round b/c resizeFactor (i.e. pixel ratio) can be float ex: Pixel 2 XL
  height = `${Math.round(parseInt(height) * resizeFactor)}`;
  if (url.includes('https://graph.facebook.com/')) {
    // facebook url - https://graph.facebook.com/#{uid}/picture?width=#{width}&height=#{height}
    let urlParams = { width: width, height: height };
    url = `${url.split('?')[0]}?${qs.stringify(urlParams)}`;
  } else if (url.includes('https://ucarecdn.com/')) {
    // uploadcare url - https://ucarecdn.com/1c24707e-8a7d-4db5-93df-d35f87ce1d36/-/crop/594x333/183,0/-/resize/820x460/
    url += `-/preview/${width}x${height}/`; // preview requires 2 dimensions. will keep ratios and fit as much as possible
  }
  return url;
}

export class Avatar extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    url: PropTypes.string,
    className: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number
  };

  static defaultProps = { width: 48, height: 48 };

  render() {
    return <ResizableImage avatar {...this.props} />;
  }
}

export class Logo extends React.Component {
  static propTypes = {
    url: PropTypes.string.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    className: PropTypes.string
  };
  static defaultProps = {
    width: 64,
    height: 64,
    url: '//via.placeholder.com/64x64'
  };

  render() {
    return <ResizableImage {...this.props} />;
  }
}

export class Hero extends React.Component {
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number
  };
  static defaultProps = {
    url: '//via.placeholder.com/128x64'
  };

  render() {
    let { height, width, ...rest } = this.props;
    if (!height || !width) {
      // allow for height or width constraint
      const ratio = 820 / 460; // upload ratio
      if (height) width = Math.round(ratio * height);
      else if (width) height = Math.round((1 / ratio) * width);
    }
    return <ResizableImage height={height} width={width} {...rest} />;
  }
}

export class Image extends React.Component {
  static propTypes = {
    url: PropTypes.string.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    className: PropTypes.string
  };
  static defaultProps = {
    url: '//via.placeholder.com/128x64'
  };

  render() {
    return <ResizableImage {...this.props} />;
  }
}

class ResizableImage extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    url: PropTypes.string,
    className: PropTypes.string,
    avatar: PropTypes.bool,
    height: PropTypes.number,
    width: PropTypes.number
  };

  render() {
    const { url, name, width, height, ...rest } = this.props;
    const adjustedUrl = resizeImageUrls({
      url,
      width,
      height,
      resizeFactor: window.devicePixelRatio
    });

    if (this.props.avatar)
      return (
        <UserAvatar
          src={adjustedUrl}
          name={name || ' '}
          {...rest}
          size={width || height}
        />
      );

    return <img {...rest} width={width} height={height} src={adjustedUrl} />;
  }
}
