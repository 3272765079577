// NOTE: There is a race condition due to how we are loading / initializing the 
// Bugsnag client via zaraz asynchronously (zaraz injection is async, as are the client init via config fetch). 
// This can lead to a situation where the app attempts to report an error before the Bugsnag client is 
// initialized (i.e. immediately on page load).

// Solves:
// 1. [Implemented] fail gracefully if Bugsnag client is not initialized and console errors [not ideal / patch]
// 2. [NTH] error queue on start up that flushes once the bugsnag client is initialized (unfortunately, bugsnag doesn't have this feature)

declare global {
  interface Window {
    Bugsnag: any;
  }
}

import { ReactNode } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary"
import { ErrorFallback } from "./ErrorFallback";

type OnErrorCallback = (...args: any[]) => void | any
type FallbackRenderer = (props: FallbackProps) => ReactNode

const logError = (error: Error, _info: { componentStack: string }) => {
  notifyError(error)
};

export const CrashErrorBoundary = ({
  children,
  onError = logError,
  FallbackComponent = ErrorFallback
}: {
  children?: any;
  onError?: OnErrorCallback;
  FallbackComponent?: FallbackRenderer
}) => {
  // NOTE: this will trigger the race condition mentioned above
  // notifyError(new Error('Test Error: CrashErrorBoundary mounted'))

  return (
    <ErrorBoundary
      fallbackRender={FallbackComponent}
      onError={onError}
    >
      {children}
    </ErrorBoundary>
  )
}

declare global {
  interface Window {
    Bugsnag: any;
  }
}

export const notifyError = (error: any, onError?: OnErrorCallback, postReportCallback?: ((...args: any[]) => void | any)) => {
  if (window.Bugsnag?.isStarted()) {
    console.log('Notifying Bugsnag of error', error)
    window.Bugsnag.notify(error, onError, postReportCallback)
  } else {
    console.log('Bugsnag not initialized or API key not found, skipping Bugsnag notification')
    console.error(error)
  }
}