// import PropTypes from 'prop-types';
import * as React from 'react';

import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';

// const createOption = label => ({
//   label,
//   value: label.toLowerCase().replace(/\W/g, '')
// });

export const groupedOptions = [
  // {
  //   label: 'Monthly Donation',
  //   options: [
  //     { value: '50M', label: '$50 a Month', amount: '50', recurring: true },
  //     { value: '100M', label: '$100 a Month', amount: '100', recurring: true }
  //   ]
  // },
  {
    label: 'Donation',
    options: [
      {
        value: '100',
        label: 'Gold',
        description: 'Suggested for families of 2 or more.',
        amount: '100'
      },
      {
        value: '50',
        label: 'Silver',
        description: 'Suggested for single students',
        amount: '50'
      },
      {
        value: 'custom',
        label: 'Other donation amount'
      }
    ]
  }
];

const Option = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <div>
        <div style={{ display: 'flex' }}>
          <div>
            <b>{props.data.label}</b>
          </div>
          <div style={{ marginLeft: 'auto' }}>{props.data.amount && <b>{props.data.amount}</b>}</div>
        </div>
        <div>
          <small>{props.data.description}</small>
        </div>
      </div>
    </components.Option>
  );
};

const SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    <div>
      <div>
        <b>{props.data.label}</b>
      </div>
    </div>
  </components.SingleValue>
);

// export default class DonationSelect extends Component {
interface PropsType {
  // nextRenewDate: object;
  onChange(val: string): void;
  onFocus(): void;
  options: any;
  menuIsOpen?: boolean;
  isDisabled?: boolean;
  defaultValue: { string: string };
  id?: string;
}

interface StateType {
  isLoading: boolean;
  // options: any;
  option: any;
}

export default class DonationSelect extends React.Component<PropsType, StateType> {
  state = {
    isLoading: false,
    option: undefined
  };

  handleChange = newOption => {
    this.props.onChange(newOption);
    // this.setState({ option: newOption });
  };

  render() {
    const { isLoading } = this.state;
    const { isDisabled, menuIsOpen, options, defaultValue, onFocus, id } = this.props;
    return (
      <React.Fragment>
        <CreatableSelect
          // isClearable
          id={id}
          isDisabled={isDisabled}
          isLoading={isLoading}
          isSearchable={false}
          components={{ Option, SingleValue }}
          // getOptionValue={option => option['id']}
          // filterOption={this.filterOption}
          // onInputChange={this.handleInputChange}
          onChange={this.handleChange}
          onFocus={onFocus}
          // onKeyDown={this.handleKeyDown}
          // formatCreateLabel={this.formatCustom}
          // isValidNewOption={this.isValidNewOption}
          // onCreateOption={this.handleCreate}
          // createOptionPositionunion={'first'}
          placeholder={'Select a level or enter a custom amount'}
          options={options}
          menuIsOpen={menuIsOpen}
          defaultValue={defaultValue}
        />
      </React.Fragment>
    );
  }

  // handleCreate = inputValue => {
  //   const { options } = this.state;
  //   const newOption = createOption(this.formatCustom(inputValue));
  //   this.setState({
  //     options: [...options, newOption],
  //     value: newOption
  //   });

  //   // this.setState({ isLoading: true });
  //   // console.group('Option created');
  //   // console.log('Wait a moment...');
  //   // setTimeout(() => {
  //   //   const { options } = this.state;
  //   //   const newOption = createOption(inputValue);
  //   //   console.log(newOption);
  //   //   console.groupEnd();
  //   //   this.setState({
  //   //     isLoading: false,
  //   //     options: [...options, newOption],
  //   //     value: newOption
  //   //   });
  //   // }, 1000);
  // };

  // handleKeyDown = e => {
  //   const allowed = !(
  //     e.keyCode !== 0 &&
  //     !e.ctrlKey &&
  //     !e.metaKey &&
  //     !e.altKey &&
  //     /^[a-zA-Z]$/.test(e.key)
  //   );

  //   console.log(`${e.key} ${allowed}`);
  //   if (!allowed) {
  //     e.preventDefault();
  //     e.stopPropagation();
  //     return false;
  //   }
  // };

  // isValidNewOption = (_inputValue, _selectValue, _selectOptions) => {
  //   return true;
  // };

  // always show all options
  // filterOption = (obj, _string) => {
  //   return obj.value != '';
  //   // console.log(obj);
  //   // console.log(string);

  //   // return true;
  // };

  // formatCustom = input => {
  //   // return null;
  //   // import formatCurrency from 'models/concerns/format_currency';
  //   const v = parseInt(input)
  //     .toFixed(2)
  //     .replace(/\d(?=(\d{3})+\.)/g, '$&,'); // 12,345.67

  //   return `$${v} One-time donation`;
  // };
}
