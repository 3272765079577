import PropTypes from 'prop-types';
import React from 'react';

export const Address = ({ address }) => {
  if (!address) return null;
  const splitAddressArr = address.split(',');
  const addr1 = splitAddressArr[0];
  const addr2 = splitAddressArr.slice(1).join(',');
  return (
    <React.Fragment>
      <div>{addr1}</div>
      <div>{addr2}</div>
    </React.Fragment>
  );
};
Address.propTypes = {
  address: PropTypes.string
};
