import { computed } from 'mobx';
import { faGift, faIdCard, faShareSquare } from '@fortawesome/free-solid-svg-icons';
import { faMoneyBillAlt } from '@fortawesome/free-regular-svg-icons';

import { BaseMembership } from 'models/base_membership';
import { NonMembers } from 'models/non_member';
import { TotemCollection } from 'models/totem';

export const TASK_ICONS = {
  download_card: faIdCard,
  make_donation: faMoneyBillAlt,
  social_share: faShareSquare,
  view_benefits: faGift,
};

export class Membership extends BaseMembership {
  urlRoot() {
    return 'user/memberships';
  }

  get nonMembers() {
    return new NonMembers(this.get('associated_non_members'));
  }

  @computed
  get associatedMembers() {
    return this.get('associated_members') && new Memberships(this.get('associated_members'));
  }

  get preregistered() {
    return this.get('status') == 'preregistered';
  }
}

export class Memberships extends TotemCollection<Membership> {
  urlRoot() {
    return 'user/memberships';
  }
  model() {
    return Membership;
  }
}

export default new Memberships();
