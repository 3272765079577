import { FallbackProps } from "react-error-boundary";

// NOTE: ChatGPT generated this component for you.

export const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Oops! Something went wrong.</h1>
      <p style={styles.message}>
        We’re sorry, but something went wrong while loading this page. Please try refreshing, or contact support if the issue persists.
      </p>
      <button style={styles.button} onClick={resetErrorBoundary || (() => window.location.reload())}>
        Reload Page
      </button>
      {error && (
        <details style={styles.details}>
          <summary>More information</summary>
          <p style={styles.messageDetails}>{error.message}</p>
        </details>
      )}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2rem',
    textAlign: 'center',
    backgroundColor: '#f8f9fa',
    color: '#333',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    maxWidth: '500px',
    margin: '10% auto 10%',
    // marginTop: '10%',
    // height: '10% auto 10%'
  },
  title: {
    fontSize: '1.5rem',
    marginBottom: '1rem',
  },
  message: {
    fontSize: '1rem',
    marginBottom: '1.5rem',
  },
  button: {
    padding: '0.75rem 1.5rem',
    fontSize: '1rem',
    backgroundColor: '#1890ff', // (global / bootstrap primary)
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  details: {
    marginTop: '1rem',
    fontSize: '0.875rem',
    color: '#666',
  },
  messageDetails: {
    marginTop: '1rem',
  }
};
