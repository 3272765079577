import { faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { observer } from 'mobx-react'
import moment from 'moment'
import React from 'react'
import { Button, Table } from 'reactstrap'

import addCartToBugsnag from 'applets/join_pta/add_cart_to_bugsnag';
import Footer from 'applets/join_pta/footer';
import { YearSelect } from 'applets/join_pta/inputs'
import { JoinPTADefaultPropTypes } from 'applets/join_pta/JoinPTADefaultPropTypes'
import cart from 'models/cart'
import currentUser from 'models/current_user'
import Donations from 'shared/donations'
import { NextButton, PrevButton } from 'shared/form_buttons'
import { StandardField, StandardForm } from 'shared/standard_field'
import { EditMemberRow } from './edit_member_row'
import { SubtotalRow } from './subtotal_row'
import { ChildMember, Teachers } from './types'

let item
let users

type CartFormProps = JoinPTADefaultPropTypes & {
  nextPath: string
  prevPath: string
  teachers: Teachers
}

type CartFormState = {
  attemptedSubmit: boolean
  preregisterEnabled: boolean
}

@observer
export class CartForm extends React.Component<CartFormProps, CartFormState> {
  availableChildMembers: ChildMember[]

  constructor(props) {
    super(props)

    item = cart.items.models[0]
    users = item.users
    this.availableChildMembers = []
    this.state = {
      attemptedSubmit: false,
      preregisterEnabled: false,
    }
  }
  componentDidMount() {
    currentUser.memberships.ensureFetched().then(async () => {
      if (cart.numUsers === 0) cart.items.models[0].users.set([{ name: '' }])
      this.setAvailableChildMembers()
      this.forceUpdate()
    })

    // prereg setup
    const { applet } = this.props
    if (applet && applet.get('preregistration')) {
      // preregistration is only toggled on for the 3 months
      // leading up to the next auto renew date

      const nextAnnualAutoRenewDateClone = moment(
        applet.nextAnnualAutoRenewDate,
      ) // clone b/c add mutates original obj
      if (!nextAnnualAutoRenewDateClone.add(-90, 'd').isAfter()) {
        cart.set({ preregistered: true })
        this.setState({ preregisterEnabled: true })
      }
    }
  }

  componentWillReact() {
    this.setAvailableChildMembers() // Update when cart modified
  }

  // Transform the childMemberships object into an array for use by Typeahead
  // Exclude users already in cart, or have an unexpired membership to org
  setAvailableChildMembers() {
    this.availableChildMembers = []
    const childMembers: any[][] = Object.entries(currentUser.childMembers)
    childMembers.forEach(([key, value]) => {
      // Exclude any users that are already in cart
      if (users.get(key)) return

      const entry: { id: string; name: string; membership_id?: string } = {
        id: key,
        name: value.name,
      }
      // Add membership_ids for any expired members of this org
      const membership = value.memberships.find(
        (membership) =>
          membership.org_unit_id === this.props.applet.get('org_unit').id,
      )
      if (membership)
        if (membership.isExpired) entry.membership_id = membership.id
        else return // Not available if they're an unexpired member
      this.availableChildMembers.push(entry)
    })
  }

  addMember = (e) => {
    e.preventDefault()
    users.add([{ name: '' }])
  }

  submit = () => {
    const { history, nextPath } = this.props
    this.setState({ attemptedSubmit: true })

    if (users.models.every((u) => u.hasValidMembersipType))
      history.push(nextPath)
  }

  renderYearSelect() {
    const { applet } = this.props
    const { preregisterEnabled } = this.state

    return (
      preregisterEnabled &&
      applet.nextAnnualAutoRenewDate && (
        <tr>
          <th colSpan={2}>
            <YearSelect
              onlyPreregister={false}
              nextRenewDate={moment(applet.nextAnnualAutoRenewDate)} // provide clone
              onSelect={(value) =>
                cart.set({ preregistered: value == 'preregister' })
              }
            />
          </th>
        </tr>
      )
    )
  }

  render() {
    const { applet, history, prevPath, teachers } = this.props

    addCartToBugsnag(cart)

    return (
      <StandardForm
        id="cart-form"
        className="d-flex flex-column flex-grow-1"
        onSubmit={this.submit}
        vanillaForm={false}
      >
        <div>
          <Table striped className="w-100">
            <thead>
              {this.renderYearSelect()}
              <tr>
                <td colSpan={2} className="text-right pr-3">
                  <Button
                    id="add-member-button"
                    className="float-right"
                    outline
                    href="#"
                    size="sm"
                    onClick={this.addMember}
                  >
                    <FontAwesomeIcon icon={faUserCircle} />
                    {' Add a Member/Student'}
                  </Button>
                </td>
              </tr>
            </thead>
            <tbody>
              {users.models.length > 0 &&
                users.models.map((user, i) => (
                  <EditMemberRow
                    id={`${applet.get('id')}-${i}`}
                    applet={applet}
                    childMemberships={this.availableChildMembers}
                    user={user}
                    key={user.id}
                    attemptedSubmit={this.state.attemptedSubmit}
                    onRemove={(user) => {
                      users.remove(user)
                    }}
                    teachers={teachers}
                  />
                ))}

              {applet.get('allow_donations') && (
                <Donations.DonationRow
                  buttonText={
                    cart.get('last_donation_amount')
                      ? 'Renew Your Donation'
                      : 'Add a Donation'
                  }
                  defaultDonation={
                    cart.get('last_donation_amount') || applet.defaultDonation
                  }
                  message={applet.get('donation_message')}
                  model={item}
                  donationLevels={applet.get('org_unit').donation_levels}
                  memberPaysDonationFeeEnabled={
                    applet.get('org_unit').member_pays_donation_fee_enabled &&
                    currentUser.get('a_b_enabled')
                  }
                  donationFeePercent={applet.get('donation_fee_percent')}
                />
              )}
              <SubtotalRow
                item="Sub-total"
                price={cart.formattedSubtotal}
                tdClass="border-dark"
              />
              <SubtotalRow
                item="Fees"
                price={cart.formattedFees}
                hint={`${applet?.get('fee')?.formatted_amount || '$1'
                  } per member${cart.donationFees > 0
                    ? ` and ${applet.get('donation_fee_percent') * 100
                    }% of donation`
                    : ''
                  } to cover processing fees and the operation of this service.`} // makes the assumption for now that a cart only supports 1 applet
              />
              <SubtotalRow item="Total" price={cart.formattedTotal} />
              {false && applet.get('allow_auto_renew') && !cart.quickAdd && (
                <SubtotalRow
                  item={`Auto-renew on ${applet.nextAnnualAutoRenewDate.format(
                    'MMMM D',
                  )}`}
                  hint="We will email you 7 days before your renewal date to allow you to confirm or change your subscriptions."
                  price={
                    <StandardField
                      id="autoRenewTip"
                      type="bootstrap-toggle"
                      containerStyle={{ float: 'right' }}
                      noLabel
                      noFormGroup
                      name="auto_renew_enabled"
                      model={cart}
                      noRow
                    />
                  }
                />
              )}
            </tbody>
          </Table>
        </div>
        <Footer
          left={
            !cart.renewingMember &&
            !cart.quickAdd && <PrevButton path={prevPath} history={history} />
          }
          right={cart.numUsers > 0 && <NextButton />}
        />
      </StandardForm>
    )
  }
}
