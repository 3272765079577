import _get from 'lodash/get';
import _set from 'lodash/set';
import isEmpty from 'lodash/isEmpty';
// import isObject from 'lodash/isObject';
import qs from 'qs';
import { action, computed, toJS, observable } from 'mobx';
import { apiClient, Model, Request } from 'mobx-rest';

import { ErrorObject, TotemCollection } from './';

// TODO Note: this is ported over from mobx-rest since it isn't exported directly
export interface SaveOptions {
  optimistic?: boolean;
  patch?: boolean;
  onProgress?: () => any;
  keepChanges?: boolean;
  path?: string;
}

export class TotemModel extends Model {
  // https://github.com/masylum/mobx-rest/blob/master/src/Model.ts

  collection: TotemCollection<this> | null = null;
  data: { [key: string]: any };
  @observable error: ErrorObject;
  fetchData: any; // only used in ensureFetched. Necessary ??

  // OVERRIDES

  baseUrl(): string {
    return super.url();
  }

  url(baseUrl?: string): string {
    // passing baseUrl prop allows for wrapping custom
    // base urls with sparseFields
    return this.appendUrlParams(baseUrl || this.baseUrl());
  }

  get(attribute) {
    let attributeValue = null;
    // for nested attributes like metadata.extra_fields.Grade
    const attributeParts = attribute.split('.');
    if (attributeParts.length > 1)
      // Pull out the first part of the attribute name, ex: metadata
      attribute = attributeParts[0];
    if (this.has(attribute)) attributeValue = super.get(attribute);
    if (attributeParts.length > 1)
      // Use lodash get to pull out the nested attribute value
      attributeValue = _get(attributeValue, attributeParts.slice(1));
    return attributeValue;
  }

  @action
  set(data) {
    if (!data) {
      super.set(data);
      return;
    }
    const setValues = {};
    Object.keys(data).forEach((key) => {
      let value;
      const keyParts = key.split('.');
      // for nested attributes like metadata.extra_fields.Grade
      if (keyParts.length > 1) {
        const fieldName = keyParts[0];
        const nestedPath = keyParts.slice(1);
        value = toJS(this.get(fieldName)) || {};
        _set(value, nestedPath, data[key]);
        key = fieldName;
      } else value = data[key];
      setValues[key] = value;
    });
    super.set(setValues);
  }

  /**
   * Fetches the model from the backend.
   */
  @action
  fetch({ data, ...otherOptions }: { data?: {} } = {}): Request {
    const { abort, promise } = apiClient().get(this.url(), data, otherOptions);

    promise
      .then((data) => {
        if (!data) return;

        this.set(data);
        this.commitChanges();

        // custom. ? Required
        this.fetchData = data;
        this.error = null;
      })
      .catch((error) => {
        // custom.
        this.error = new ErrorObject(
          // label,
          error
        );
        this.fetchData = null;
      }); // do nothing

    return this.withRequest('fetching', promise, abort);
  }

  /**
   * Call an RPC action for all those
   * non-REST endpoints that you may have in
   * your API.
   */
  @action
  rpc(method: string | { rootUrl: string }, options?: {}, label: string = 'calling'): Request {
    // const url = isObject(endpoint) ? endpoint.rootUrl : `${this.url()}/${endpoint}`;

    // custom logic to generate url
    let url = this.baseUrl();
    if (method) url += `/${method}`;
    url = this.appendUrlParams(url);

    const { promise, abort } = apiClient().post(url, options);

    return this.withRequest(label, promise, abort);
  }

  // CUSTOM LOGIC

  @action
  patch(attrs?): Request {
    const changes = attrs || this.changes;

    let request = this.save(
      {
        id: this.get('id'),
        type: this.get('type'),
        ...changes,
      },
      { patch: true }
    );

    return request;
  }

  @action
  save(attributes?: {}, saveOptions: SaveOptions = {}): Request {
    const request = super.save(attributes, saveOptions);
    request.promise
      .then(() => {
        this.error = null;
      })
      .catch((error) => {
        // note, this error has already been passed into the mobx-rest ErrorObject
        // constructor, so grab error.payload to init our custom ErrorObject
        // https://github.com/masylum/mobx-rest/blob/master/src/Base.ts#L44
        this.error = new ErrorObject(
          // label,
          error.payload
        );
      });
    return request;
  }

  // sparse fields

  createUrlParamsDataObj() {
    let data: { [key: string]: any } = {};
    if (this.data) {
      data = this.data;
      if (data.fields) {
        Object.keys(data.fields).forEach((fieldName: string) => {
          if (Array.isArray(data.fields[fieldName])) data.fields[fieldName] = data.fields[fieldName].join(',');
        });
      }
      if (data.include && Array.isArray(data.include)) data.include = data.include.join(',');
    } else {
      // use collection sparse fields if no sparse fields on model
      if (this.collection) {
        data = this.collection.createUrlParamsDataObj();
      }
    }
    return data;
  }

  appendUrlParams(url) {
    const urlParamsData = this.createUrlParamsDataObj();
    if (!isEmpty(urlParamsData)) url += `?${qs.stringify(urlParamsData)}`;
    return url;
  }

  // TODO enesure fetched will need to be modified given the new Request structure for models

  ensureFetched(options?: { [key: string]: any }): Request {
    if (this.isRequest('fetching')) return this.getRequest('fetching');
    else if (this.fetchData || this.error) {
      // prevents infinite loop of fetches if original fetch errored out
      const promise = new Promise((resolve, reject) => {
        if (!this.error) resolve(this.fetchData);
        else reject(this.errorBody);
      });

      return new Request(promise);
    } else return this.fetch(options);
  }

  // error handling

  get errorBody() {
    if (this.error && this.error.body) return this.error.body;
    return null;
  }

  nullifyError() {
    this.error = null;
  }

  @computed
  get notFound() {
    return this.errorBody && !!this.errorBody.not_found;
  }
}
