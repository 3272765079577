import { v1 as uuidv1 } from 'uuid';
import { computed, observable, toJS } from 'mobx';

import formatCurrency from 'models/concerns/format_currency';
import { TotemModel } from 'models/totem';
import { CartItems } from './CartItems';

export { CartUser, CartUsers } from './CartUsers';
export { CartItem, CartItems } from './CartItems';

export class Cart extends TotemModel {
  constructor(attributes) {
    super(
      Object.assign(
        {
          auto_renew_enabled: true,
          id: uuidv1(),
          items: new CartItems(),
          preregistered: false,
        },
        attributes
      )
    );
  }

  baseUrl() {
    // override b/c cart has id but don't want id included in api enpoint
    return this.urlRoot();
  }

  urlRoot() {
    return 'applets/join_pta';
  }

  @computed
  get renewingMember() {
    return this.get('renewing_member');
  }

  @computed
  get quickAdd() {
    return this.get('quickAdd');
  }

  @computed
  get validForCartStep() {
    return this.has('membership_type');
  }

  @computed
  get validForCheckoutStep() {
    return (
      this.validForCartStep &&
      // Fail if any applets are not active
      !this.items.models.find((item) => !item.applet.isActive)
    );
  }

  @computed
  get donationFees() {
    return this.items.models.reduce((acc, item) => acc + item.cleanDonationFee, 0);
  }

  @computed
  get fees() {
    return this.items.models.reduce((acc, item) => acc + item.fees, 0);
  }

  @computed
  get formattedFees() {
    return formatCurrency(this.fees, 'USD');
  }

  @computed
  get subtotal() {
    return this.items.models.reduce((acc, item) => acc + item.subtotal, 0);
  }

  @computed
  get formattedSubtotal() {
    return formatCurrency(this.subtotal, 'USD');
  }

  @computed
  get total() {
    return this.items.models.reduce((acc, item) => acc + item.total, 0);
  }

  @computed
  get formattedTotal() {
    return formatCurrency(this.total, 'USD');
  }

  @computed
  get items() {
    return this.get('items');
  }

  @computed
  get numUsers() {
    return this.items.models.reduce((acc, item) => acc + item.numUsers, 0);
  }

  toJS() {
    const attrs = observable.map({
      type: 'cart',
      attributes: {
        auto_renew_enabled: this.get('auto_renew_enabled'),
        id: this.get('id'),
        items: this.items.toJS(),
        total: this.formattedTotal,
        preregistered: this.get('preregistered'),
      },
    });

    return toJS(attrs, { exportMapsAsObjects: true });
  }
}

export default new Cart({});
