import React from 'react';

import { faCcAmex, faCcDiscover, faCcMastercard, faCcVisa } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type CreditCardIconProps = {
  kind: string;
  className?: string;
  fixedWidth?: boolean;
  style?: { [key: string]: any };
};

export const CreditCardIcon: React.FC<CreditCardIconProps> = ({ kind, ...rest }) => {
  const cardIcons = {
    Visa: faCcVisa,
    Discover: faCcDiscover,
    MasterCard: faCcMastercard,
    'American Express': faCcAmex,
  };
  return <FontAwesomeIcon icon={cardIcons[kind]} {...rest} />;
};
