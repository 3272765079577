import { computed } from 'mobx';

import { TotemCollection, TotemModel } from 'models/totem';

export const NAME_REGEX = /^\S+\s\S+.*$/;
export const NAME_REGEX_STRING = NAME_REGEX.toString().slice(1, -1);

export class User extends TotemModel {
  urlRoot() {
    return 'users';
  }

  @computed
  get validName() {
    return this.has('name') && NAME_REGEX.test(this.get('name'));
  }
}

export class Users extends TotemCollection<User> {
  urlRoot() {
    return 'users';
  }
  model() {
    return User;
  }
}

export default new Users();
