// https://github.com/masylum/mobx-rest-jquery-adapter/blob/master/src/index.js

// @flow
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import merge from 'lodash/merge';
import jquery from 'jquery';

const $ = jquery;

type Request = {
  abort: () => void;
  promise: Promise<any>;
};

type Method = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
type Options = {
  method: Method;
  headers?: { [key: string]: string };
  xhrFields: { [key: string]: any };
  onProgress?: (num: number) => any;
  data?: { [key: string]: any };
};

function xhrWithProgress(options: { onProgress?: (value: number) => any }) {
  return () => {
    const myXhr = $.ajaxSettings.xhr();

    if (myXhr.upload && options.onProgress) {
      myXhr.upload.addEventListener(
        'progress',
        (prog) => {
          const progress = Math.ceil((prog.loaded / prog.total) * 100);
          options.onProgress && options.onProgress(progress || 100);
          // ^ This is just for flow
        },
        false
      );
    }

    return myXhr;
  };
}

function ajaxOptions(options: Options): {} {
  if (options.method === 'GET') {
    return options;
  }

  const formData = new FormData();
  let hasFile = false;

  forEach(options.data, (val: any, attr: string) => {
    hasFile = hasFile || val instanceof File;
    if (!isNull(val)) formData.append(attr, val);
  });

  const baseOptions = {
    method: options.method,
  };

  if (hasFile) {
    return Object.assign({}, baseOptions, {
      cache: false,
      processData: false,
      data: formData,
      xhrFields: options.xhrFields,
      headers: options.headers,
      xhr: xhrWithProgress(options),
      contentType: false,
    });
  }

  const { xhrFields, headers, data, ...rest } = options;
  return Object.assign(
    {},
    baseOptions,
    {
      contentType: 'application/json',
      headers: headers,
      xhrFields: xhrFields,
      data: options.data ? JSON.stringify(data) : null,
    },
    rest
  );
}

function parseJson(str: string): { [key: string]: any } {
  try {
    return JSON.parse(str);
  } catch (_error) {
    return null;
  }
}

export function ajax(url: string, options: Options): Request {
  const xhr = $.ajax(url, ajaxOptions(options));

  const promise = new Promise((resolve, reject) => {
    xhr.done(resolve).fail((jqXHR) => {
      const json = parseJson(jqXHR.responseText);
      let ret = json ? json.errors : {};
      if (isEmpty(ret)) {
        if (jqXHR.responseJSON && !isEmpty(jqXHR.responseJSON.errors)) ret = jqXHR.responseJSON.errors;
      }

      return reject(ret || {});
    });
  });

  const abort = () => xhr.abort();

  return { abort, promise };
}

type RequestPayload = {
  type: string;
  id: string;
  attributes: { [key: string]: any };
  relationships: { [key: string]: any }[];
};

export default {
  apiPath: '',
  commonOptions: {},

  get(path: string, data?: RequestPayload, options?: {}): Request {
    return ajax(`${this.apiPath}${path}`, merge({}, { method: 'GET', data }, this.commonOptions, options));
  },

  post(path: string, data?: RequestPayload, options?: {}): Request {
    return ajax(
      `${this.apiPath}${path}`,
      merge({}, { method: 'POST', data: processPostData(data) }, this.commonOptions, options)
    );
  },

  put(path: string, data?: RequestPayload, options?: {}): Request {
    return ajax(
      `${this.apiPath}${path}`,
      merge({}, { method: 'PUT', data: processPostData(data) }, this.commonOptions, options)
    );
  },

  patch(path: string, data?: RequestPayload, options?: {}): Request {
    return ajax(
      `${this.apiPath}${path}`,
      merge({}, { method: 'PATCH', data: processPostData(data) }, this.commonOptions, options)
    );
  },

  del(path: string, options?: {}): Request {
    return ajax(`${this.apiPath}${path}`, merge({}, { method: 'DELETE' }, this.commonOptions, options));
  },
};

function processPostData(inputData?: RequestPayload): {} {
  let { type, id, attributes, relationships, ...rest } = inputData;
  let data: {
    data: {
      type: string;
      attributes: { [key: string]: any };
      id?: string;
      relationships?: { [key: string]: any }[];
    };
  } = {
    data: {
      type: type,
      attributes: attributes || rest,
    },
  };
  if (id) {
    data.data.id = id;
  }
  if (relationships) {
    data.data.relationships = relationships;
  }
  return data;
}
