import { computed } from 'mobx';

import { TotemCollection, TotemModel } from 'models/totem';

export class PayMethod extends TotemModel {
  urlRoot() {
    return 'user/pay_methods';
  }

  get last4() {
    return this.get('last_digits');
  }

  get isPrimary() {
    return this.get('status') == 'primary';
  }

  @computed
  get method() {
    const method = this.get('method');
    if (method === 'apple_pay') return 'apple';
    if (method === 'android_pay') return 'google';
    return method;
  }

  deactivate() {
    let request = this.save(
      {
        id: this.get('id'),
        type: 'pay_method',
        ...{ aasm_event: 'deactivate', aasm_event_save: true },
      },
      { patch: true }
    );

    return request;
  }
}

class PayMethods extends TotemCollection<PayMethod> {
  urlRoot() {
    return 'user/pay_methods';
  }

  model() {
    return PayMethod;
  }
}

export default new PayMethods();
