import React from 'react';
import { faApple, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { faCreditCard, faLock, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormFeedback, ListGroup, ListGroupItem } from 'reactstrap';
import { observer } from 'mobx-react';
import { useElements, useStripe } from '@stripe/react-stripe-js';

import AddPayMethodForm, { onSavePayMethod } from 'shared/stripe/AddPayMethodForm';
import currentUser from 'models/current_user';
import Footer from 'applets/join_pta/footer';
import { CreditCard } from 'shared/molecules/credit_card';
import { NavButton, PrevButton } from 'shared/form_buttons';
import { parseStripeError } from '../util/parseStripeError';

type CheckoutFormBaseProps = typeof CheckoutForm.defaultProps & {
  amount: any;
  history?: any;
  checkout: (...args: any[]) => any;
  formattedTotal?: string;
  displayPrev?: boolean;
  checkoutButtonText: any;
  checkoutButtonDisabled?: boolean;
  prevPath?: string;
  showActive: boolean;
  paymentRequestLabel: string;
};

type CheckoutFormInjectedProps = CheckoutFormBaseProps & {
  elements: any;
  stripe: any;
};

type CheckoutFormState = {
  addingPayMethod: boolean;
  canMakePaymentRequest: boolean | string;
  canSubmit: boolean;
  errorMessage: string;
  paymentRequestButtonDisabled: boolean;
  payMethod: any;
  submitting: boolean;
};

@observer
class CheckoutForm extends React.Component<CheckoutFormInjectedProps> {
  static defaultProps = {
    showActive: true,
  };

  addPayMethodForm = React.createRef<AddPayMethodForm>();
  paymentRequest: any;

  state: CheckoutFormState = {
    addingPayMethod: false,
    canMakePaymentRequest: false,
    canSubmit: false,
    errorMessage: null,
    paymentRequestButtonDisabled: false,
    payMethod: null,
    submitting: false,
  };

  constructor(props) {
    super(props);

    this.paymentRequest = props.stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: this.props.paymentRequestLabel,
        amount: Math.trunc(this.props.amount),
      },
    });

    this.paymentRequest.canMakePayment().then((result) => {
      if (result) {
        this.paymentRequest.on('source', ({ source, complete }) => {
          const request = onSavePayMethod(source);
          request.promise
            .then((payMethod) => {
              this.props.checkout({
                setState: this.setErrorState,
                payMethod: payMethod,
                successCallback: () => complete('success'),
                failureCallback: () => complete('fail'),
              });
            })
            .catch((e) => {
              console.error(e);
              complete('fail');
              this.setState({
                errorMessage:
                  e.stripe_id ||
                  parseStripeError(e) ||
                  'There was an error and you have not been charged. Our staff has been alerted.',
              });
            });
        });
        this.paymentRequest.on('cancel', () => {
          this.setState({ paymentRequestButtonDisabled: false });
        });
        this.setState({
          canMakePaymentRequest: result.applePay ? 'applePay' : true,
        });
      }
    });
  }

  setErrorState = (errorState) => {
    this.setState(errorState);
  };

  componentDidMount() {
    currentUser.payMethods.ensureFetched().then(async () => {
      const primaryPayMethod = currentUser.payMethods.find({
        status: 'primary',
      });
      primaryPayMethod && this.setExistingPayMethod(primaryPayMethod);
    });
  }

  beforeAddPayMethod = () => this.setState({ submitting: true });

  afterAddPayMethod = (responsePromise) => {
    return new Promise<void>((resolve, reject) => {
      responsePromise
        .then((payMethod) =>
          this.props.checkout({
            setState: this.setErrorState,
            payMethod: payMethod,
            finallyCallback: () => resolve(),
          })
        )
        .catch(() => {
          this.setState({ submitting: false });
          reject();
        });
    });
  };

  showPaymentRequest = () => {
    this.paymentRequest.show();
  };

  onClickPaymentRequestButton = (e) => {
    e.preventDefault();
    this.setState({ paymentRequestButtonDisabled: true }, this.showPaymentRequest);
  };

  setExistingPayMethod = (method) => {
    this.setState({
      canSubmit: true,
      errorMessage: null,
      addingPayMethod: false,
      payMethod: method,
    });
  };

  stripeInputChanged = ({ complete }) => this.setState({ canSubmit: complete });

  submit = (e) => {
    e.preventDefault();

    const { payMethod } = this.state;

    if (payMethod) {
      this.setState({ submitting: true });
      this.props.checkout({
        setState: this.setErrorState,
        payMethod: payMethod,
      });
    } else this.addPayMethodForm.current.onSubmit();
  };

  render() {
    const {
      addingPayMethod,
      canMakePaymentRequest,
      canSubmit,
      errorMessage,
      paymentRequestButtonDisabled,
      payMethod,
      submitting,
    } = this.state;

    const { history, displayPrev, checkoutButtonText, checkoutButtonDisabled, prevPath, stripe, elements } = this.props;

    const canApplePay = canMakePaymentRequest == 'applePay';
    return (
      !currentUser.payMethods.isRequest('fetching') && (
        <div className="d-flex flex-column flex-grow-1">
          <div>
            <ListGroup flush>
              {currentUser.payMethods.models.map((method) => (
                <ListGroupItem
                  key={method.id}
                  id={`pay-method-${method.id}`}
                  tag="a"
                  href="#"
                  action
                  active={this.props.showActive && payMethod && payMethod.id === method.id}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setExistingPayMethod(method);
                  }}
                >
                  <CreditCard card={method} />
                </ListGroupItem>
              ))}
              {canMakePaymentRequest && (
                <ListGroupItem
                  tag="a"
                  href="#"
                  action
                  className="d-flex align-items-center fade show"
                  disabled={paymentRequestButtonDisabled}
                  onClick={this.onClickPaymentRequestButton}
                >
                  <FontAwesomeIcon
                    fixedWidth
                    icon={canApplePay ? faApple : faGoogle}
                    className="mr-3"
                    style={{ fontSize: '2.6rem' }}
                  />
                  {canApplePay ? 'Apple' : 'Google'} Pay
                </ListGroupItem>
              )}
              {addingPayMethod || (currentUser.payMethods.models.length === 0 && !canMakePaymentRequest) ? (
                <ListGroupItem>
                  <AddPayMethodForm
                    id="add-pay-method-form"
                    className="d-flex flex-column flex-grow-1"
                    elements={elements}
                    ref={this.addPayMethodForm}
                    beforeSubmitCallback={this.beforeAddPayMethod}
                    promiseCallback={this.afterAddPayMethod}
                    stripeInputChanged={this.stripeInputChanged}
                    stripe={stripe}
                  >
                    {window['RELEASE_STAGE'] !== 'production' && (
                      <p className="text-center text-muted">
                        Test Mode: use card 4242424242424242 with any future expiration date, CVC, &amp; zip
                      </p>
                    )}
                  </AddPayMethodForm>
                </ListGroupItem>
              ) : (
                <ListGroupItem
                  tag="a"
                  href="#add-pay-method-form"
                  action
                  className="d-flex align-items-center border-bottom"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      canSubmit: false,
                      errorMessage: null,
                      addingPayMethod: true,
                      payMethod: null,
                    });
                  }}
                >
                  <FontAwesomeIcon fixedWidth icon={faCreditCard} className="mr-3" style={{ fontSize: '2.6rem' }} />
                  New Credit or Debit card
                </ListGroupItem>
              )}

              {errorMessage && (
                <ListGroupItem>
                  <FormFeedback className="clearfix text-center" style={{ display: 'inherit' }}>
                    {errorMessage}
                  </FormFeedback>
                </ListGroupItem>
              )}
            </ListGroup>
          </div>
          <Footer
            className="border-top-md-0"
            left={displayPrev ? <PrevButton path={prevPath} history={history} /> : null}
            middle=" "
            right={
              <NavButton
                id="checkout-button"
                disabled={submitting || !canSubmit || checkoutButtonDisabled}
                color={canSubmit && !checkoutButtonDisabled ? 'success' : 'secondary'}
                className="float-right"
                icon={submitting ? faSpinner : faLock}
                iconPosition="left"
                text={canSubmit ? checkoutButtonText.onCanSubmit : checkoutButtonText.onCannotSubmit}
                onClick={this.submit}
              />
            }
          />
        </div>
      )
    );
  }
}

type StripeInjectedCheckoutFormProps = {
  innerRef?: (...args: any[]) => any;
} & CheckoutFormBaseProps;

export const StripeInjectedCheckoutForm: React.FC<StripeInjectedCheckoutFormProps> = (
  props: StripeInjectedCheckoutFormProps
) => {
  const elements = useElements();
  const stripe = useStripe();

  if (!elements || !stripe) return null;

  const { innerRef, ...rest } = props;
  return <CheckoutForm {...rest} elements={elements} ref={innerRef} stripe={stripe} />;
};
