import React, { FC, useState } from 'react';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UncontrolledTooltip } from 'reactstrap';

type SubtotalRowProps = {
  item: string;
  price: any;
  hint?: string;
  tdClass?: string;
};

export const SubtotalRow: FC<SubtotalRowProps> = ({ item, price, hint, tdClass }) => {
  const [elementRef] = useState<string>(
    'ID' +
      Math.random()
        .toString()
        .slice(-8)
  ); // Generate random ID hint to differentiate it from others on the page

  const renderHint = () => {
    return (
      hint && (
        <span>
          <FontAwesomeIcon id={elementRef} icon={faQuestionCircle} className="text-muted ml-1" />
          <UncontrolledTooltip target={elementRef}>{hint}</UncontrolledTooltip>
        </span>
      )
    );
  };
  return (
    <tr>
      <td className={`text-right ${tdClass}`}>
        <span>{item}</span>
        {renderHint()}
      </td>
      <td className={`text-right align-middle w-25 pl-1 ${tdClass}`}>{price}</td>
    </tr>
  );
};
