import { computed } from 'mobx';

import cleanNumber from 'models/concerns/clean_number';
import formatCurrency from 'models/concerns/format_currency';
import { TotemModel } from 'models/totem';

export class AcceptsDonation extends TotemModel {
  get donationFee() {
    return this.get('donationFee');
  }

  get donationMeta() {
    return this.get('donationMeta');
  }

  get currency() {
    throw 'AcceptsDonationError: currency getter must be defined by subclass';
  }

  @computed
  get cleanDonation() {
    return cleanNumber(this, 'donation');
  }

  @computed
  get cleanDonationFee() {
    return cleanNumber(this, 'donationFee');
  }

  @computed
  get cleanDonationPlusFee() {
    return this.cleanDonation + this.cleanDonationFee;
  }

  @computed
  get formattedDonation() {
    return formatCurrency(this.cleanDonation, this.currency);
  }

  @computed
  get formattedDonationFee() {
    return formatCurrency(this.cleanDonationFee, this.currency);
  }

  @computed
  get formattedDonationPlusFee() {
    return formatCurrency(this.cleanDonationPlusFee, this.currency);
  }
}
