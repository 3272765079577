import * as React from 'react';
import { FC, useState } from 'react';

import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons';
// import ToggleButton from 'react-toggle-button';

import Donations from 'shared/donations';

// const { observableArray } = PropTypes;

interface DonationRowProps {
  buttonText: string;
  donationFeePercent: number;
  model: any;

  afterSelect: () => void;
  defaultDonation?: number;
  disabled?: boolean;
  donationLevels?: any; //observableArray;
  memberPaysDonationFeeEnabled?: boolean;
  message?: string;
  onDisableEdit?: () => void;
  onEnableEdit?: () => void;
  onSelectFocus?: () => void;
}

type ViewStateType = 'static' | 'callToAction' | 'editing';

export const DonationRow: FC<DonationRowProps> = ({
  buttonText,
  donationFeePercent,
  model,
  afterSelect: afterSelectProp,
  defaultDonation,
  disabled,
  donationLevels,
  memberPaysDonationFeeEnabled = false,
  message,
  onDisableEdit,
  onEnableEdit,
  onSelectFocus,
}: DonationRowProps) => {
  const [viewState, setViewState] = useState<ViewStateType>(model.has('donation') ? 'static' : 'callToAction');
  const [memberPaysDonationFeeOn, setMemberPaysDonationFeeOn] = useState<boolean>(
    memberPaysDonationFeeEnabled && model.cleanDonationFee > 0
  );

  const enableEdit = () => {
    setViewState('editing');
    if (onEnableEdit) onEnableEdit();
  };

  const disableEdit = () => {
    setViewState('static');
    if (onDisableEdit) onDisableEdit();
  };

  const afterSelect = () => {
    if (memberPaysDonationFeeOn) setMemberPaidDonationFee();

    disableEdit();
    if (afterSelectProp) afterSelectProp();
  };

  const enableDonation = (e) => {
    e.preventDefault();
    enableEdit();
  };

  const calculateDonationFee = (donationString: string): string => {
    const donation = parseFloat(donationString);
    const newDonation = donation / (1 - donationFeePercent);
    const donationFee = newDonation - donation;
    return donationFee.toFixed(2);
  };

  const setMemberPaidDonationFee = (): void => {
    model.set({ donationFee: calculateDonationFee(model.get('donation')) });
  };

  const toggleMemberPaysDonation = (oldVal: boolean) => {
    const val = !oldVal;
    if (val && model.get('donation') && model.get('donation') != '') setMemberPaidDonationFee();
    else {
      // clear fee
      model.set({ donationFee: null });
    }
    setMemberPaysDonationFeeOn(val);
  };

  const renderCTARow = () => {
    return (
      <tr className="donation-row table-success">
        <td colSpan={2} className="text-center">
          <Button id="add-donation-button" color="success" size="sm" onClick={enableDonation} disabled={disabled}>
            <FontAwesomeIcon icon={faGraduationCap} />
            <span style={{ marginLeft: 10 }}>{buttonText}</span>
          </Button>
          {message && (
            <p className="mt-1 mb-0">
              <span className="small">{message}</span>
            </p>
          )}
        </td>
      </tr>
    );
  };

  const renderEditRow = () => {
    return (
      <tr className="donation-row">
        <td colSpan={2}>
          <Donations.Input
            model={model}
            options={donationLevels}
            afterSelect={afterSelect}
            onSelectFocus={onSelectFocus}
            defaultDonation={defaultDonation ? defaultDonation.toString() : ''}
          />
        </td>
      </tr>
    );
  };

  // const renderMemberPaysDonation = () => {
  //   if (memberPaysDonationFeeEnabled)
  //     return (
  //       <tr>
  //         <td>
  //           <span className="mr-1">
  //             I would like to cover all processing fees so the association receives the full amount of my donation.
  //           </span>
  //         </td>
  //         <td style={{ verticalAlign: 'middle' }}>
  //           <ToggleButton
  //             containerStyle={{ float: 'right' }}
  //             value={memberPaysDonationFeeOn}
  //             inactiveLabel=""
  //             activeLabel="ON"
  //             onToggle={toggleMemberPaysDonation}
  //             passThroughInputProps={{ id: 'member-pays-donation-fee-toggle' }}
  //           />
  //         </td>
  //       </tr>
  //     );
  // };

  const renderStaticRow = () => {
    let donationLevel: string;
    if (model.donationMeta) donationLevel = model.donationMeta.headline;

    return (
      <>
        <tr className="donation-row" onClick={enableEdit}>
          <td>
            <div>Tax-deductible Donation {!!donationLevel && donationLevel != 'Custom' && `(${donationLevel})`}</div>
            <span className="small">Thank you! </span>
            {Donations.renderSmileys(model.cleanDonation)}
          </td>
          <td className="text-right align-middle">{model.formattedDonation}</td>
        </tr>
        {/* {renderMemberPaysDonation()} */}
      </>
    );
  };

  if (viewState == 'editing') return renderEditRow();
  else if (viewState == 'callToAction' || model.cleanDonation == 0) return renderCTARow();
  else return renderStaticRow();
};
