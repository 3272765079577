import PropTypes from 'prop-types';
import React from 'react';

import { StandardField } from 'shared/standard_field';

export class EditableMembershipExtraField extends React.Component {
  static propTypes = {
    field: PropTypes.object.isRequired,
    noLabel: PropTypes.bool,
    labelColumns: PropTypes.number
  };

  static defaultProps = { labelColumns: 3 };

  placeHolder = field => {
    let placeholderText = '';
    if (this.props.noLabel) placeholderText = `${field.name} `;
    if (field.placeholder)
      placeholderText = `${placeholderText}${field.placeholder} `;

    return placeholderText.trim();
  };

  render() {
    const { field, ...rest } = this.props;
    return (
      <StandardField
        label={field.name}
        labelColumns={this.props.labelColumns}
        name={`metadata.extra_fields.${field.name}`}
        placeholder={this.placeHolder(field)}
        {...rest}
        type={field.type}
        required={field.required}
      />
    );
  }
}
