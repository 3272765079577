import React from 'react';
import { CardBody } from 'reactstrap';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react';
import isEmpty from 'lodash/isEmpty';

import cart from 'models/cart';
import currentUser from 'models/current_user';
import Footer from 'applets/join_pta/footer';
import { JoinPTADefaultPropTypes } from 'applets/join_pta/JoinPTADefaultPropTypes';
import { RegistrationForm } from './form';

const nextPath = '/cart';

type RegistrationViewState = {
  emailReg: boolean;
};

@observer
export class RegistrationView extends React.Component<JoinPTADefaultPropTypes, RegistrationViewState> {
  state = { emailReg: false };

  componentDidMount() {
    currentUser.data = {
      fields: {
        user: ['name', 'email', 'identities', 'a_b_enabled'],
      },
    };

    currentUser.memberships.sparseFields = {
      memberships: [
        'associated_members',
        'auto_renew_enabled',
        'last_donation_amount',
        'membership_type',
        'org_unit_id',
        'status',
        'past_expiration_date',
        'metadata'
      ],
    };

    currentUser.ensureFetched().then(async () => {
      if (!window['PREVIEW'])
        currentUser.memberships.ensureFetched({ data: { page: { size: 100 } } }).then(async () => {
          const org = this.props.applet.get('org_unit');
          if (org) {
            const membership = currentUser.memberships.find({
              org_unit_id: org.id,
            });
            if (membership) {
              let users;
              cart.set({
                auto_renew_enabled: membership.get('auto_renew_enabled'),
                membership_type: this.props.applet.membershipTypes.find({
                  id: membership.membershipType.id,
                }),
              });
              if (membership.isRenewable) {
                cart.set({
                  last_donation_amount: membership.lastDonationAmount,
                  renewing_member: true,
                });
                users = [
                  {
                    id: currentUser.get('id'),
                    name: currentUser.get('name'),
                    membership_id: membership.get('id'),
                    membership_type: cart.get('membership_type'),

                  },
                ];
                if (!isEmpty(membership.get('metadata'))) users[0].metadata = membership.get('metadata')
                membership.associatedMembers.models.forEach((member) => {
                  if (member.isRenewable) {
                    const d = {
                      id: member.user.get('id'),
                      name: member.user.get('name'),
                      membership_id: member.get('id'),
                      membership_type: this.props.applet.membershipTypes.find({
                        id: member.membershipType.id,
                      }),
                    }
                    if (!isEmpty(member.get('metadata'))) d['metadata'] = member.get('metadata')
                    users.push(d)
                  }
                });
                cart.items.models[0].users.set(users, { remove: false });
              } else cart.set({ quickAdd: true });
              this.props.history.replace(nextPath);
            }
          }
        });
    });
  }

  renderRegistration = () => {
    if (currentUser.isRequest('fetching') || currentUser.memberships.isRequest('fetching')) return '';

    if (currentUser.has('id')) return <RegistrationForm {...this.props} />;

    return (
      <>
        <div className="text-center flex-grow-1">
          <p>{this.props.applet.get('welcome_message')}</p>

          <div className="d-flex flex-wrap">
            {!window['APPLE_AUTH_DISABLED'] && <a className="btn-social-auth btn-apple-auth" href="/users/auth/apple">Sign in with Apple</a>}
            {!window['GOOGLE_AUTH_DISABLED'] && <a className="btn-social-auth btn-google-auth" href="/users/auth/google">Sign in with Google</a>}
            {!window['MICROSOFT_AUTH_DISABLED'] && <a className="btn-social-auth btn-microsoft-auth" href="/users/auth/microsoft">Sign in with Microsoft</a>}
            {!window['FACEBOOK_AUTH_DISABLED'] && <a className="btn-social-auth btn-facebook-auth" href="/users/auth/facebook">Sign in with Facebook</a>}
          </div>

          <p className="mt-0 mb-2 small text-muted">{"We'll never post without permission"}</p>
          <hr className="hr-text" data-content="or" />

          <a href="/users/sign_up?show_email_reg=1" className="btn btn-social-auth btn-email-auth">
            Join with email
          </a>
          <p className="mt-2">
            <span className="small text-muted">
              By signing up, you are agreeing to our <a href="/legal/terms-of-use">Terms of Use</a>
            </span>
          </p>
        </div>
        <Footer className="remove-card-body-margin" middle={<span className="brand">Powered by TOTEM</span>} />
      </>
    );
  };

  render() {
    return (
      <CardBody className="d-flex flex-column pb-0" style={{ minHeight: 200 }}>
        {this.renderRegistration()}
      </CardBody>
    );
  }
}
