export class ErrorObject {
  // label: string;
  body: { [key: string]: any };
  constructor(
    //label: string,
    body: {}
  ) {
    // this.label = label;
    this.body = body;
  }
}

// Brought in from mobx-rest
export interface SetOptions {
  add?: boolean;
  change?: boolean;
  remove?: boolean;
  data?: {};
}

export { TotemCollection, CollectionFetchOptions } from './collection';
export { TotemModel } from './model';
