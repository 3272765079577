import { computed } from 'mobx';

import formatCurrency from './concerns/format_currency';
import { TotemCollection, TotemModel } from 'models/totem';

export class Payment extends TotemModel {
  constructor(attributes) {
    super(
      Object.assign(
        {
          summary: 'TODO: Implement this virtual attribute',
        },
        attributes
      )
    );
  }

  urlRoot() {
    return 'user/payments';
  }

  @computed
  get summary() {
    let summary: any = {
      id: this.get('id'),
      amount: this.get('amount').formatted_amount,
      createdAt: this.get('created_at'),
      note: this.get('note'),
    };
    const metadata = this.get('metadata');
    if (metadata?.items?.[0]?.users) {
      const itemMetadata = metadata.items[0];

      summary['users_with_membership_purchased'] = itemMetadata['users'].map((user) => user.name);
      summary['membership_purchased'] = summary['users_with_membership_purchased'].length > 0;
      summary['donation'] = itemMetadata['donation'].formatted_amount;
      summary['donationMade'] = summary['donation'] != formatCurrency(0, this.get('currency'));

      summary['org_unit_name'] = itemMetadata['org_unit_common_name'] || itemMetadata['org_unit_name'];

      const refundAmount = itemMetadata['refund_amount'];
      if (refundAmount && parseFloat(refundAmount.amount) > 0) summary['refundAmount'] = refundAmount.formatted_amount;
    } else if (metadata?.orders?.[0]) {
      // assume one order per payment for now
      const order = metadata.orders[0];
      summary['order_id'] = order.id;
      summary['order_status'] = order.status;
      summary['org_unit_name'] = order.org_unit_name;
      summary['store_name'] = order.store_name;
      summary['store_id'] = order.store_id;
      const reduce_products = order.items.reduce((acc, item) => {
        if (acc[item.product_id]) acc[item.product_id].qty += item.qty;
        else acc[item.product_id] = { qty: item.qty, product_name: item.product_name };
        return acc;
      }, {});
      summary['order_products'] = Object.values(reduce_products);
      const refundAmount = order.refund_amount
      if (refundAmount && parseFloat(refundAmount.amount) > 0) summary['refundAmount'] = refundAmount.formatted_amount;
    }
    return summary;
  }

  @computed
  get paymentItemsSummaries() {
    const membershipPaymentItems = this.get('payment_items').filter(
      (paymentItem) => paymentItem.pays_for_type == 'Membership'
    );
    const donationPaymentItems = this.get('payment_items').filter(
      (paymentItem) => paymentItem.pays_for_type == 'OrgUnit'
    );
    const orderPaymentItems = this.get('payment_items').filter(
      (paymentItem) => paymentItem.pays_for_type == 'Shop::Order'
    );

    let summaries = {
      currency: this.get('currency'),
      membership: [],
      donation: [],
      order: [],
      fees: 0,
      subtotal: 0,
      donationTotal: 0,
      membershipTotal: 0,
      orderTotal: 0,
      refundAmount: 0,
      refundFees: 0,
      total: 0,
    };

    membershipPaymentItems.forEach((membershipPaymentItem) => {
      const summary = `${membershipPaymentItem.metadata.membership.user_name} (${membershipPaymentItem.metadata.membership.membership_type}) membership`;
      summaries.membership.push({
        item: summary,
        amount: membershipPaymentItem.net_amount,
        fee: membershipPaymentItem.fee,
        refundAmount: membershipPaymentItem.refund_amount,
      });
    });

    donationPaymentItems.forEach((donationPaymentItem) => {
      const metadata = donationPaymentItem.metadata ? donationPaymentItem.metadata.donation_meta : {};

      let dItem = {
        item: 'Donation',
        amount: donationPaymentItem.amount,
        metadata: metadata,
        refundAmount: donationPaymentItem.refund_amount,
      };

      summaries.donation.push(dItem);
    });

    // assume one order per payment for now
    const orderPaymentItem = orderPaymentItems[0]

    if (orderPaymentItem) {
      const order = this.get('metadata').orders?.[0]
      const order_refunded = Number(orderPaymentItem.refund_amount.amount) > 0

      order.items.forEach((orderItem) => {
        summaries.order.push({
          item: orderItem.product_name,
          variant_id: orderItem.variant_id,
          variant_options: orderItem.variant_options,
          amount: orderItem.sub_total,
          qty: orderItem.qty,
          refundAmount: order_refunded ? orderItem.sub_total : 0, // assume full refund for now
        });
      })

      if (!order_refunded) summaries.orderTotal = summaries.order.reduce((acc, item) => acc + Number(item.amount.amount), 0);
    }

    summaries['membershipTotal'] = summaries['membership'].reduce(
      (acc, item) => acc + (Number(item.refundAmount.amount) > 0 ? 0 : Number(item.amount.amount)),
      0
    );

    summaries['donationTotal'] = summaries['donation'].reduce(
      (acc, item) => acc + Number(item.amount.amount) - Number(item.refundAmount.amount),
      0
    );

    summaries['refundAmount'] = this.get('payment_items').reduce(
      (amount, item) => amount + parseFloat(item.refund_amount.amount),
      0
    );

    // org absorbs donation fees so ignore those

    summaries['fees'] = [...membershipPaymentItems, ...orderPaymentItems].reduce((acc, item) => acc + Number(item.fee.amount), 0);
    summaries['refundFees'] = [...membershipPaymentItems, ...orderPaymentItems].reduce((acc, item) => acc + Number(item.refund_fee.amount), 0);

    summaries['subtotal'] = summaries['membershipTotal'] + summaries['donationTotal'] + summaries['orderTotal'];

    summaries['total'] = summaries['fees'] - summaries['refundFees'] + summaries['subtotal'];

    return summaries;
  }
}

export class Payments extends TotemCollection<Payment> {
  urlRoot() {
    return 'user/payments';
  }

  model() {
    return Payment;
  }

  @computed
  get paymentSummaries() {
    return this.models.map((payment) => payment.summary);
  }
}

export default new Payments();
