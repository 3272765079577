import 'promise.prototype.finally/auto';
import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Elements } from '@stripe/react-stripe-js';
import { Provider } from 'mobx-react';

import totemClient from 'mobx-rest-jsonapi-client';

import { CrashErrorBoundary } from './CrashReporting'

export default function renderMobxRestApp({
  RootComponent,
  basename,
  stripePromise
}) {
  const browserHistory = createBrowserHistory({ basename: basename });

  const rootElement = document.getElementById('root');

  if (!rootElement) return null;

  let rootComponent = stripePromise ? (
    <Elements stripe={stripePromise}>
      <RootComponent history={browserHistory} />
    </Elements>
  ) : (
    <RootComponent history={browserHistory} />
  );

  rootElement &&
    ReactDOM.render(
      <Provider client={totemClient}>
        <CrashErrorBoundary>{rootComponent}</CrashErrorBoundary>
      </Provider>,
      rootElement
    );
}
