import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'reactstrap';
import {
  faChevronCircleLeft,
  faChevronCircleRight,
  faSpinner
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export class NavButton extends React.Component {
  render() {
    const {
      className,
      color,
      icon,
      iconPosition,
      onClick,
      text,
      type,
      ...buttonProps
    } = this.props;
    const iconTag = icon && (
      <FontAwesomeIcon icon={icon} spin={icon === faSpinner} />
    );

    return (
      <Button
        outline={!color}
        color={color}
        className={className}
        onClick={onClick}
        type={type}
        {...buttonProps}
      >
        <span className="nobr">
          {iconPosition === 'left' && iconTag} {text}{' '}
          {iconPosition === 'right' && iconTag}
        </span>
      </Button>
    );
  }
}
NavButton.propTypes = {
  iconPosition: PropTypes.oneOf(['left', 'right']).isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  text: PropTypes.string,
  type: PropTypes.string
};

export class PrevButton extends React.Component {
  render() {
    return (
      <NavButton
        className="float-left"
        icon={faChevronCircleLeft}
        iconPosition="left"
        text="Back"
        type="button"
        onClick={e => {
          e.preventDefault();
          this.props.history.push(this.props.path);
        }}
      />
    );
  }
}
PrevButton.propTypes = {
  history: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired
};

export class NextButton extends React.Component {
  render() {
    return (
      <NavButton
        color="primary"
        className="float-right"
        icon={faChevronCircleRight}
        iconPosition="right"
        text="Next"
        type="submit"
        onClick={this.props.onClick}
      />
    );
  }
}
NextButton.propTypes = {
  onClick: PropTypes.func
};
