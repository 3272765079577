import React from 'react';

import { faApple, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CreditCardIcon } from 'shared/atoms/credit_card_icon';
import { Badge } from 'reactstrap';

import { PayMethod } from 'models/pay_method';

type CreditCardProps = {
  card: PayMethod;
  isPrimary?: boolean;
};

export const CreditCard: React.FC<CreditCardProps> = ({ card, isPrimary = false, ...rest }) => {
  return (
    <div>
      <CreditCardIcon
        fixedWidth
        kind={card.get('kind')}
        className="float-left mr-3"
        style={{ fontSize: '2.6rem' }}
        {...rest}
      />
      {card.method && (
        <>
          <FontAwesomeIcon icon={card.method === 'apple' ? faApple : faGoogle} /> Pay{' '}
        </>
      )}
      {card.get('name') && `${card.get('name')}: `}
      •••• {card.get('last_digits')} {isPrimary && <Badge color="success">Primary</Badge>}
      <div className="small nobr">
        Exp: {card.get('exp_month')}/{card.get('exp_year')}{' '}
        {card.get('postal_code') && ` | Zip: ${card.get('postal_code')}`}
      </div>
    </div>
  );
};
