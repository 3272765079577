import { TotemCollection, TotemModel } from 'models/totem';

export class NonMember extends TotemModel {
  urlRoot() {
    return 'user/non_members';
  }
}

export class NonMembers extends TotemCollection {
  urlRoot() {
    return 'user/non_members';
  }
  model() {
    return NonMember;
  }
}

export default new NonMembers();
