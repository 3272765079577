import PropTypes from 'prop-types';
import React from 'react';
import { FormFeedback, Input, InputGroup, InputGroupAddon } from 'reactstrap';

export class CurrencyInput extends React.Component {
  static propTypes = {
    bsSize: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    errorMessage: PropTypes.string
  };

  render() {
    const { name, value, bsSize, errorMessage, ...inputProps } = this.props;
    return (
      <InputGroup size={bsSize}>
        <InputGroupAddon addonType="prepend">$</InputGroupAddon>
        <Input
          type="tel"
          inputMode="numeric"
          name={name}
          value={value}
          {...inputProps}
        />
        {errorMessage && <FormFeedback>{errorMessage}</FormFeedback>}
      </InputGroup>
    );
  }
}
