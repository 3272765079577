import { apiClient } from 'mobx-rest';
import { computed } from 'mobx';

import membershipsCollection from 'models/membership';
import paymentsCollection from 'models/payment';
import payMethodsCollection from 'models/pay_method';
import { TotemModel } from 'models/totem';

export class CurrentUser extends TotemModel {
  baseUrl() {
    // override b/c currentUser has id but don't want id included in api enpoint
    return this.urlRoot();
  }
  urlRoot() {
    return 'user/me';
  }

  @computed
  get splitName() {
    return this.get('name').split(' ');
  }

  @computed
  get firstName() {
    return this.splitName[0];
  }

  @computed
  get lastName() {
    return this.splitName[this.splitName.length - 1];
  }

  @computed
  get hasActiveLeaderships() {
    return this.get('leaderships').length > 0;
  }

  @computed
  get leaderships() {
    return this.get('leaderships');
  }

  get memberships() {
    return membershipsCollection;
  }

  get payMethods() {
    return payMethodsCollection;
  }

  get payments() {
    return paymentsCollection;
  }

  fetchTxnReceipt(txnID) {
    const url = `user/users/${this.id}/token_purchase_transfers/${txnID}`;
    const { promise } = apiClient().get(url);
    return promise;
  }

  @computed
  get orgIDsForLeadershipsWOutMembership() {
    let membershipOrgIDs = this.membershipOrgIDs;
    return this.get('leaderships')
      .filter((leadership) => !membershipOrgIDs.includes(leadership.org_unit.id))
      .map((leadership) => leadership.org_unit.id);
  }

  @computed
  get membershipOrgIDs() {
    return this.memberships.models.map((membership) => membership.get('org_unit').id);
  }

  // Create an object with keys = unique user ids of all child members
  // and an array of all memberships for each of those users
  @computed
  get childMembers() {
    const childMembers = {};
    this.memberships.models.forEach((model) =>
      (model.get('associated_members') || []).forEach((child) => {
        const membership = {
          id: child.id,
          membership_type_id: child.membership_type.id,
          org_unit_id: model.get('org_unit_id'),
          status: child.status,
        };
        if (childMembers[child.user.id]) childMembers[child.user.id]['memberships'].push(membership);
        else
          childMembers[child.user.id] = {
            memberships: [membership],
            name: child.user.name,
          };
      })
    );
    return childMembers;
  }

  unauthorizedTest() {
    apiClient().get('unauthorized');
  }
}

export default new CurrentUser();
