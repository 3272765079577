import React, { FC, useEffect, useState } from 'react';
import { CardBody } from 'reactstrap';

import addCartToBugsnag from './add_cart_to_bugsnag';
import cart from 'models/cart';
import Footer from './footer';
import { errorEvent, purchaseEvent } from 'shared/analytics';
import { JoinPTADefaultPropTypes } from './JoinPTADefaultPropTypes';
import { PrevButton } from 'shared/form_buttons';
import { StripeInjectedCheckoutForm } from 'shared/stripe/CheckoutForm';
import { parseStripeError } from 'shared/stripe/util/parseStripeError';

const prevPath = '/cart';

type CheckoutViewProps = JoinPTADefaultPropTypes & {};

export const CheckoutView: FC<CheckoutViewProps> = (props) => {
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!cart.validForCartStep) props.history.push(prevPath);
    else setLoaded(true);
  }, []);

  const checkout = ({ setState, payMethod, successCallback, failureCallback, finallyCallback }) => {
    const postData: any = cart.toJS();
    postData.attributes.pay_method_id = payMethod.id;
    const request = cart.rpc('checkout', postData);
    request.promise
      .then((success) => {
        typeof successCallback === 'function' && successCallback();
        // GA purchase event
        purchaseEvent(
          cart.id,
          postData.attributes.total,
          typeof payMethod.get === 'function' ? payMethod.get('method') : payMethod.method
        );
        const membershipId = success.primary_membership.id;
        window.location.href = membershipId
          ? `/dashboard/memberships/${membershipId}?from_checkout=1`
          : '/dashboard/memberships';
      })
      .catch((e) => {
        console.error(e);
        typeof failureCallback === 'function' && failureCallback();
        const errorMessage =
          e.items ||
          e.pay_method ||
          e.total ||
          parseStripeError(e) ||
          'There was an error and you have not been charged. Our staff has been alerted.';
        setState({
          submitting: false,
          errorMessage: errorMessage,
        });
        // GA error event for cart post error
        errorEvent('join error', errorMessage, null, Math.round(cart.total));
      })
      .finally(() => {
        typeof finallyCallback === 'function' && finallyCallback();
      });
  };

  if (!loaded) return null;

  if (cart.validForCheckoutStep && !window['PREVIEW'] && !window['BANNER']) {
    addCartToBugsnag(cart);
    const cartAmount = cart.total * 100;
    const orgUnit = props.applet.orgUnit
    return (
      <StripeInjectedCheckoutForm
        {...props}
        checkout={checkout}
        amount={cartAmount}
        formattedTotal={cart.formattedTotal}
        checkoutButtonText={{
          onCanSubmit: `Pay ${cart.formattedTotal} now`,
          onCannotSubmit: `${cart.formattedTotal} due`,
        }}
        prevPath={prevPath}
        paymentRequestLabel={orgUnit.type == 'pta' ? 'PTA Memberships' : 'Memberships'}
      />
    );
  } else
    return (
      <>
        <CardBody className="pb-0">
          <p>This applet is in preview mode. Payment is disabled.</p>
        </CardBody>
        <Footer className="border-top-md-0" left={<PrevButton path={prevPath} history={props.history} />} />
      </>
    );
};
