import { computed } from 'mobx';

import { TotemCollection, TotemModel } from 'models/totem';

export class Setting extends TotemModel {
  urlRoot(): string {
    return `settings`;
  }

  get configuration(): { [key: string]: any } {
    return this.get('configuration');
  }

  // get id(): string {
  //   return this.get('id');
  // }

  @computed
  get settable(): { id: string; type: string } {
    return { id: this.get('settable_id'), type: this.get('settable_type') };
  }

  get type(): string {
    return this.get('type');
  }

  @computed
  get safeConfiguration(): {[key: string]: any} {
    // ensure all expected configuration values are present here
    // to avoid having to check in multiple places in the UI components
    throw('subclass must define this method')
  }
}

export class Settings extends TotemCollection<Setting> {
  urlRoot() {
    return 'lookups';
  }

  model() {
    return Setting;
  }
}

export default new Settings();
