export function errorEvent(category, action, label) {
  const event = { category, action, label }

  try {
    return zaraz?.track('Error', event)
  } catch (error) {
    console.error('Error tracking error event', event, error)
    return null
  }
}

export function purchaseEvent(cartID, totalAmount, payMethod) {
  const event = {
    order_id: cartID,
    total: totalAmount,
    payment_type: payMethod,
  }

  try {
    return zaraz?.ecommerce('Order Completed', event)
  } catch (error) {
    console.error('Error tracking purchase event', event, error)
    return null
  }
}
