import Input from './input';
import LevelEditorForm from './level_editor_form';
import Preview from './preview';
import Select from './select';
import { DonationRow } from './donation_row_input';

export default class Donations {
  static DonationRow = DonationRow;
  static Input = Input;
  static LevelEditorForm = LevelEditorForm;
  static Preview = Preview;
  static Select = Select;

  static renderSmileys(donation) {
    let len = Math.min(3, donation.toString().split('.')[0].length);
    return '\uD83D\uDE03'.repeat(len); // 😃
  }
}
