import React, { FC, useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { Badge, Button, Col, FormGroup, Row } from 'reactstrap';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import cart, { CartUser } from 'models/cart';
import currentUser from 'models/current_user';
import { ChildMember, Teachers } from './types';
import { EditableMembershipExtraField } from 'shared/organisms/editable_membership_extra_field';
import { GradeSelect } from 'shared/inputs/grade-select';
import { JoinPTA } from 'models/applets/join_pta';
import { NAME_REGEX_STRING } from 'models/user';
import { StandardField, StandardFieldContent } from 'shared/standard_field';
import { studentNonMember } from 'models/lookup';
import { TeacherSelect } from 'shared/inputs/teacher-select';

type EditMemberRowProps = {
  applet: JoinPTA;
  childMemberships: ChildMember[];
  user: CartUser;
  id: string;
  attemptedSubmit?: boolean;
  onRemove?: (...args: any[]) => any;
  teachers: Teachers;
};

export const EditMemberRow: FC<EditMemberRowProps> = ({
  applet,
  childMemberships,
  user,
  id,
  attemptedSubmit,
  onRemove,
  teachers,
}: EditMemberRowProps) => {
  // NOTE: this state shouldn't be needed but is a workaround for a mobx bug
  // where teacher metadata updates don't trigger a re-render. Avoiding mobx
  // updates for now to avoid a larger refactor. MJS 08/23/2024
  const [membershipMetadata, setMembershipMetadata] = useState(user.get('metadata'));
  const [viewState, setViewState] = useState<'static' | 'editing'>(
    user.validName && user.hasValidMembersipType ? 'static' : 'editing'
  );
  const [showMemTypeErrorStyles, setShowMemTypeErrorStyles] = useState<boolean>(false);
  const membershipTypeRef = useRef(Select);
  let nameInput: any = useRef<StandardFieldContent>(null); // TODO workaround b/w StandardFieldContent is written in JS. Something wonkey about ref implementation there.
  // let membershipTypeInput = useRef(StandardFieldContent);

  useEffect(() => {
    if (user.get('metadata') !== membershipMetadata) {
      setMembershipMetadata(user.get('metadata'));
    }
  }, [user.get('metadata')]);

  const enableEdit = () => setViewState('editing');

  const disableEdit = () => setViewState('static');

  const validMembershipType = () => {
    return (
      (!!membershipTypeRef.current.state.value || !!membershipTypeRef.current.state.inputValue) &&
      user.hasValidMembersipType
    );
  };

  const checkIfDone = (e) => {
    const validMembType = validMembershipType();
    const input = nameInput ? (nameInput.current ? nameInput.current.input : nameInput.input) : null;
    const valid = validMembType && (!input || input.checkValidity());
    if (valid) {
      document.getElementById('cart-form').classList.remove('was-validated');
      disableEdit();
    } else {
      e.preventDefault();
      if (!validMembType) setShowMemTypeErrorStyles(true);
      document.getElementById('cart-form').classList.add('was-validated');
    }
  };

  const editRowID = () => `edit-member-row-${id}`;

  const showMembershipTypeExtraFieldsErrorStyles = (key) => {
    return (showMemTypeErrorStyles || attemptedSubmit) && !user.get(key);
  };

  const renderEditForm = () => {
    const membershipType = user.membershipType;
    let membershipTypeOptions = applet.membershipTypes.asRichOptionsWithLabel('name');
    if (!cart.quickAdd && user.id != currentUser.id) {
      // Allow user to be Student (Non-Member) iff
      // 1) user is not the primary user AND
      // 2) it is not a quick add
      membershipTypeOptions.splice(membershipTypeOptions.findIndex((mT) => mT.label == 'Student') + 1, 0, {
        name: studentNonMember.get('name'),
        label: studentNonMember.get('name'),
        value: studentNonMember,
      });
    }

    const defaultMembershipType = user.membershipType
      ? membershipTypeOptions.find((mType) => user.membershipType.get('id') == mType.value.get('id'))
      : null;

    const teacherOptions = teachers.map((t) => ({ label: t, value: t }));

    return (
      <tr id={editRowID()}>
        <td colSpan={2}>
          <Row className="align-items-center">
            <Col xs={9}>
              {user.has('id') || user.has('membership_id') ? (
                <FormGroup>
                  <span className="input-color notranslate">{user.get('name')}</span>
                </FormGroup>
              ) : (
                <StandardField
                  required
                  autoFocus
                  flip
                  highlightOnlyResult
                  selectHintOnEnter
                  emptyLabel={false}
                  type="typeahead"
                  options={childMemberships as any[]}
                  labelKey="name"
                  noLabel
                  // bsSize="sm"
                  defaultErrorMessage="Full name required"
                  placeholder="Member name"
                  name="name"
                  model={user}
                  innerRef={(ref) => {
                    nameInput = ref;
                  }}
                  pattern={NAME_REGEX_STRING}
                />
              )}
              <StandardField
                id={`membership-type-select-${id}`}
                type="react-select"
                isSearchable={false}
                // reactSelectInnerRef={ref => (this.membershipTypeRef = ref)}
                reactSelectInnerRef={membershipTypeRef}
                options={membershipTypeOptions}
                noLabel
                placeholder="Choose one"
                name="membership_type"
                model={user}
                // innerRef={membershipTypeInput}
                reactSelectDefaultValue={defaultMembershipType}
                showErrorStyles={user.membershipType == null && (showMemTypeErrorStyles || attemptedSubmit)}
                resetErrorStyles={() => setShowMemTypeErrorStyles(false)}
                required
              />
              {user.membershipTypeExtraFields &&
                user.membershipTypeExtraFields.map((field, i) => {
                  const extraFields = user.get('metadata').extra_fields;

                  if (field.name == 'Grade') {
                    const extraFieldsKey = 'metadata.extra_fields.Grade';
                    const gradeSelectID = `grade-select-${id}`;
                    return (
                      <div id={`grade-select-${id}`} className="mt-3" key={i}>
                        <GradeSelect
                          id={gradeSelectID}
                          onChange={(value) => {
                            setShowMemTypeErrorStyles(false);
                            user.set({
                              [extraFieldsKey]: value,
                            });
                          }}
                          defaultInputValue={extraFields['Grade'] || ''}
                          styles={{
                            control: (provided) =>
                              showMembershipTypeExtraFieldsErrorStyles(extraFieldsKey)
                                ? {
                                  ...provided,
                                  borderColor: 'red',
                                }
                                : provided,
                          }}
                        />
                      </div>
                    );
                  } else if (field.name == 'Teacher') {
                    const extraFieldsKey = 'metadata.extra_fields.Teacher';
                    const teacherSelectID = `teacher-select-${id}`;
                    return (
                      <div id={teacherSelectID} className="mt-3 mb-3" key={i}>
                        <TeacherSelect
                          id={teacherSelectID}
                          options={teacherOptions}
                          onChange={(value) => {
                            setShowMemTypeErrorStyles(false);
                            user.set({
                              [extraFieldsKey]: value,
                            });

                            const updatedMembershipMetadata = {
                              ...membershipMetadata,
                              extra_fields: {
                                ...membershipMetadata.extra_fields,
                                Teacher: value,
                              },
                            }
                            setMembershipMetadata(updatedMembershipMetadata);
                          }}
                          value={extraFields['Teacher'] || ''}
                          styles={{
                            control: (provided) =>
                              showMembershipTypeExtraFieldsErrorStyles(extraFieldsKey)
                                ? {
                                  ...provided,
                                  borderColor: 'red',
                                }
                                : provided,
                          }}
                        />
                      </div>
                    );
                  }
                  return (
                    <EditableMembershipExtraField
                      field={field}
                      key={i}
                      noLabel
                      // bsSize="sm"
                      model={user}
                    />
                  );
                })}
            </Col>
            <Col>
              <div className="text-right align-middle">{membershipType && membershipType.formattedPrice}</div>
            </Col>
          </Row>
          <Row>
            <Col xs={9}>
              <FormGroup className="mb-0">
                <>
                  {user.id === currentUser.id || (
                    <Button
                      color="link"
                      className="text-muted pl-0"
                      size="sm"
                      onClick={() => {
                        document.getElementById('cart-form').classList.remove('was-validated');
                        onRemove(user);
                      }}
                    >
                      <FontAwesomeIcon icon={faMinusCircle} size="sm" /> Remove
                    </Button>
                  )}
                  <Button type="submit" size="sm" className="float-right" onClick={checkIfDone}>
                    Save
                  </Button>
                </>
              </FormGroup>
            </Col>
          </Row>
        </td>
      </tr>
    );
  };

  const membershipType = user.membershipType;
  if (viewState === 'editing') return renderEditForm();

  return (
    <tr id={editRowID()} onClick={enableEdit}>
      <td>
        <div>
          <span className="notranslate">{`${user.get('name')} `}</span>
          {user.get('membership_id') ? (
            <Badge pill color="secondary" className="font-weight-normal">
              Renewing
            </Badge>
          ) : (
            <Badge pill color="primary" className="font-weight-normal">
              New
            </Badge>
          )}
        </div>
        <span className="small">
          {user.membershipTypeName}
          {user.membershipTypeName.includes('Student') && (
            <StudentDetails
              grade={user.get('metadata').extra_fields['Grade']}
              teacher={user.get('metadata').extra_fields['Teacher']}
            />
          )}
        </span>
      </td>
      <td className="text-right align-middle">{membershipType && membershipType.formattedPrice}</td>
    </tr>
  );
};

type StudentDetailsProps = {
  grade?: string;
  teacher?: string;
};

const StudentDetails: FC<StudentDetailsProps> = ({ grade, teacher }) => {
  const getNumberWithOrdinal = (n) => {
    let s = ['th', 'st', 'nd', 'rd'],
      v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  };

  const formatGrade = (grade) => {
    if (isNaN(grade)) return grade;
    return `${getNumberWithOrdinal(grade)} grade`;
  };

  return (
    <div>
      {grade && <span className="mr-1">{formatGrade(grade)}</span>}
      {teacher && <span className="mr-1 notranslate">{teacher}</span>}
    </div>
  );
};
