import { Collection, apiClient, Request } from 'mobx-rest';
import isEmpty from 'lodash/isEmpty';
import qs from 'qs';
import { action, IObservableArray } from 'mobx';

import { ErrorObject, TotemModel, SetOptions } from './';
import { exportToExcel } from 'shared/helpers';

export interface CollectionFetchOptions extends SetOptions {
  preProcessData?: (models: IObservableArray) => IObservableArray;
}

export class TotemCollection<T extends TotemModel> extends Collection<T> {
  // https://github.com/masylum/mobx-rest/blob/master/src/Collection.ts
  error: ErrorObject;
  fetchData: any;
  include: string[] | string;
  meta: { [key: string]: any };
  sparseFields: { [key: string]: any };
  urlParams: { [key: string]: any };

  // OVERRIDES

  urlRoot(): string {
    throw new Error('`urlRoot` method not implemented');
  }

  url() {
    return this.appendUrlParams(this.urlRoot());
  }

  /**
   * Specifies the model class for that collection
   */
  model(attributes?: { [key: string]: any }): new (attributes?: { [key: string]: any }) => T | null {
    throw 'Must implement model';
  }

  /**
   * Fetches the models from the backend.
   *
   * It uses `set` internally so you can
   * use the options to disable adding, changing
   * or removing.
   */
  @action
  fetch({ data, ...otherOptions }: CollectionFetchOptions = {}): Request {
    const { preProcessData } = otherOptions;
    const { abort, promise } = apiClient().get(this.url(), data, otherOptions);

    promise
      .then((data) => {
        // ADDED
        if (data.models) {
          if (preProcessData) data.models = preProcessData(data.models);

          this.set(data.models, otherOptions);
          this.models.forEach((model) => (model.error = null));
        }

        // ADDED
        if (data.raw) this.meta = data.raw.meta;
        this.error = null;
        this.fetchData = data;
      })
      .catch((error) => {
        // ADDED
        this.error = new ErrorObject(error);
        this.fetchData = null;
      }); // do nothing

    return this.withRequest('fetching', promise, abort);
  }

  // CUSTOM LOGIC

  ensureFetched(options?: { data?: {} }) {
    if (this.isRequest('fetching')) return this.getRequest('fetching');
    else if (this.fetchData) return new Promise((resolve) => resolve(this.fetchData));
    else return this.fetch(options);
  }

  appendUrlParams(url) {
    const urlParamsData = this.createUrlParamsDataObj();
    if (!isEmpty(urlParamsData)) url += `?${qs.stringify(urlParamsData)}`;
    return url;
  }

  createUrlParamsDataObj(options?: { [key: string]: any }) {
    let data = options ? options.data : {};
    if (this.sparseFields) {
      //iterate through entire sparseFields object
      for (const [modelName, fields] of Object.entries(this.sparseFields)) {
        if (!data.fields) data.fields = {};
        if (Array.isArray(fields)) {
          data.fields[modelName] = fields.join(',');
        } else {
          data.fields[modelName] = fields;
        }
      }
    }
    if (this.include) {
      if (Array.isArray(this.include)) {
        data.include = this.include.join(',');
      } else {
        data.include = this.include;
      }
    }
    if (this.urlParams) {
      data = Object.assign(data, this.urlParams);
    }
    return data;
  }

  reset(data) {
    this.fetchData = null;
    this.meta = null;
    super.reset(data);
  }

  export(args) {
    args.collection = this;
    return exportToExcel(args);
  }

  //  @computed
  //  get raw() {
  //    return this.fetchData && this.fetchData.raw;
  //  }
}
